<template>
  <div>
    <slot name="screen">
    <b-navbar toggleable="md" class="navbar navbar-light bg-white navbar-expand-md" id="navbar">
      <a class="navbar-brand" href="/"><img src="../assets/sh_text_only.svg" height=30></a>
      <!--
      <b-navbar-toggle target="navbarDefault">
      </b-navbar-toggle>
      -->
      <div
        v-on:click="showMenu = !showMenu"
        class='menu_toggle text-center text-muted d-md-none'>
        <i v-if="!showMenu" class="fa fa-close text-danger"></i>
        <i v-else class="fa fa-bars fa-lg"></i>
        {{ showMenu ? 'SHOW MENU' : 'HIDE MENU' }}
      </div>
      <div class="ml-auto navbar-nav flex-row"
           :class="{'d-md-flex d-none': showMenu}">
        <!--
          <a class="nav-item nav-link" href="#">Wiki</a>
          <a class="nav-item nav-link" href="#">Applications</a>
          <a class="nav-item nav-link" href="#">Data</a>
        -->
        <span class="navbar-text mr-3">
          <strong>{{ user.email }}</strong>
        </span>
        <span class="navbar-text" v-if="user.permissions.is_whitelisted_ip && !user.email">( <i class="fa fa-unlock-alt"> </i> hospital network )</span>
        <a v-if="user.email" class="nav-item nav-link" href="#" v-on:click.stop.prevent="logout()"><i class="fa fa-sign-out"></i> logout</a>
        <router-link v-if="!user.email" class="nav-item nav-link" :to="{name: 'AppLogin'}"><i class="fa fa-sign-in"></i> login</router-link>
      </div>
    </b-navbar>
    <div class="container-fluid">
      <div class="row">
        <slot name="main-container">
        <nav class="col-md-3 col-xl-2 d-flex" id="sidebar">
          <ul class="nav flex-column mt-2 mt-md-0"
              :class="{'d-md-flex': showMenu, 'd-none': showMenu }">
            <slot name="sidebar">
            </slot>
          </ul>
          <ul class="nav flex-column"
              :class="{'d-md-flex': showMenu, 'd-none': showMenu }">
            <li v-for="link in sidebarLinks" v-if="evaluatePermission(link.permission)">
              <router-link class="nav-link frame-link" active-class="active" :to="link.route_link">
                <i class="fa fa-fw" :class="'fa-' + link.icon"></i>{{ link.text }}
              </router-link>
            </li>
            <li>
              <a v-if='user.permissions.is_whitelisted_ip' class="nav-link frame-link" href="/docs/">
                <i class="fa fa-fw fa-book"></i>Antibiotic Policy</a>
              <a v-else class="nav-link frame-link" href="http://docs.hic-sahyadri.org/">
                <i class="fa fa-fw fa-book"></i>Antibiotic Policy</a>
            </li>
            </li>
            <li>
              <a v-if='user.permissions.is_whitelisted_ip' class="nav-link frame-link" href="/xrays/static/index.html">
                <i class="fa fa-fw fa-book"></i>Quick Xrays</a>
            </li>
            <li>
              <a class="nav-link frame-link" v-if="user.permissions.is_staff" href="/admin"><i class="fa fa-fw fa-lock"></i>Admin</a>
            </li>
          </ul>
        </nav>
        <div class="main-content-wrapper col-xl-10 col-md-9" role="main">
          <div class="main-content p-3">
            <slot name="main"></slot>
          </div>
        </div>
        </slot>
      </div>
    </div>
    <footer class="my-5 text-muted">
      <div class="container-fluid">
        <div class="row">
          <div class="ml-auto mr-auto col-md-6 text-center">
            This application is maintained by the <strong>Infection Control Department</strong> at
            <a href="http://www.sahyadrihospital.com">Sahyadri Hospitals</a><br>Email: cic@sahyadrihospitals.com
          </div>
        </div>
      </div>
    </footer>
    </slot>
    <div v-if="showLoadingAnimation" class="loading_animation">Loading...</div>
    <notification-bar></notification-bar>
  </div>
</template>

<script>
// import router from '@/router'
import { mapGetters } from 'vuex'
import NotificationBar from '@/components/shared/notificationBar'
import _ from 'lodash'

export default {
  name: 'AppFrame',
  data () {
    return {
      loading: true,
      showMenu: true,
      sidebarLinks: [
        {
          text: 'Alert Antibiotics',
          icon: 'bell',
          permission: ['is_staff', 'is_whitelisted_ip'],
          route_link: { name: 'RequestList' }
        },
        {
          text: 'Issue Tracker',
          icon: 'flag',
          permission: ['is_staff'],
          route_link: { name: 'IssueTrackerList' }
        },
        {
          text: 'Device Surveillance',
          icon: 'binoculars',
          permission: ['is_staff'],
          route_link: { name: 'SurveillanceList' }
        },
        {
          text: 'VAE Monitoring',
          icon: 'binoculars',
          permission: ['is_staff', 'is_whitelisted_ip'],
          route_link: { name: 'VAEList' }
        },

        {
          text: 'Surgical Site Monitoring',
          icon: 'binoculars',
          permission: ['is_staff'],
          route_link: { name: 'SurgeryMonitoringList', query: { status: 'added' } }
        },
        {
          text: 'Covid Charts',
          icon: 'binoculars',
          permission: ['is_staff', 'is_whitelisted_ip'],
          route_link: { name: 'CovidChartList' }
        },
        {
          text: 'Culture Archives',
          icon: 'snowflake-o',
          permission: ['is_staff', 'is_whitelisted_ip'],
          route_link: { name: 'CultureSearch' }
        },
        {
          text: 'Configuration',
          icon: 'gear',
          permission: ['config_app.can_edit_ui'],
          route_link: { name: 'ConfigIndex' }
        }
      ]
    }
  },
  methods: {
    logout () {
      this.$store.dispatch('logout_user').then(() => {
        this.$router.push({ name: 'AppLogin' })
        this.$store.dispatch('display_notification', {
          message: 'Successfully logged out'
        })
      })
    },
    evaluatePermission (permissions) {
      return _.some(permissions, permission => {
        return _.get(this.user.permissions, permission, false) || this.user.permissions.all_permissions.includes(permission)
      })
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    showLoadingAnimation () {
      return this.$store.state.loading_animation
    },
    ...mapGetters([
      'hospital'
    ])
  },
  components: {
    'notification-bar': NotificationBar
  }
}
</script>
<style scoped>
.main-content {
  min-height: 500px;
}

.menu_toggle {
  font-size: 12px;
  font-weight: 600;
}

@media (max-width: 767px) {
  .main-content-wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}

.frame-link.active {
  /* display: none!important; */
}

.loading_animation{
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 2px 5px;
  background-color: yellow;
}

.app_initialization_container {
  position:absolute;
  height:100%;
  width:100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.app_initialization {
  max-width: 80%;
  color: #E40000;
  font-family:sans-serif;
  font-size:30px;
  font-weight:100;
  text-align: center;
}
</style>
