import CovidFrame from './Frame'
import CovidChartForm from './Form'
import CovidChartList from './List'
import CovidChartStart from './StartSheet'
import CovidChartDetail from './Detail'
import TestArchive from './TestArchive.vue'
import AdmissionsSearch from './AdmissionsSearch.vue'
import RedirectToCovidChart from './Redirector.vue'

export default {
  path: '/app/:hospital/surveillance/covid_charts',
  name: 'CovidChart',
  component: CovidFrame,
  children: [
    {
      path: 'list',
      name: 'CovidChartList',
      component: CovidChartList,
      props: (route) => (
        {
          page: typeof route.query.page === 'undefined' ? 1 : parseInt(route.query.page)
        }
      )
    },
    {
      path: 'start',
      name: 'CovidChartStart',
      component: CovidChartStart
    },
    {
      path: 'patient/:patientId/sheet/new',
      name: 'CovidChartFormNew',
      component: CovidChartForm,
      meta: { edit: false }
    },
    {
      path: 'patient/:patientId/sheet/:documentId',
      name: 'CovidChartDetail',
      component: CovidChartDetail
    },
    {
      path: 'patient/:patientId/sheet/:documentId/edit',
      name: 'CovidChartFormEdit',
      component: CovidChartForm,
      meta: { edit: true }
    },
    {
      path: 'patient/:hospitalId/test-archive',
      name: 'TestArchive',
      component: TestArchive
    },
    {
      path: 'patient/:hospitalId/redirect_to_chart',
      name: 'RedirectToCovidChart',
      component: RedirectToCovidChart
    },
    {
      path: 'admissions/search',
      name: 'AdmissionsSearch',
      component: AdmissionsSearch
    }

  ]
}
