<template>
  <Datepicker
    @selected="update"
    :input-class="input_class"
    v-model="internalDate">
    <div slot="beforeCalendarHeader" class="calendar-header d-flex">
      <button class="btn btn-outline-danger btn-sm m-auto" @click.prevent="clear()"><i class='fa fa-times'> </i> Clear Date</button>
    </div>
  </Datepicker>
</template>
<script>
import { format, parse, isEqual } from 'date-fns'
// import _ from 'lodash'
import Datepicker from 'vuejs-datepicker'

// const DISPLAY_FORMAT = 'dd-MM-yyyy'
const INTERNAL_FORMAT = 'yyyy-MM-dd'

export default {
  props: {
    value: [String],
    input_class: {
      type: [String, Object],
      default: 'form-control'
    }
  },
  data () {
    return {
      internalDate: null
    }
  },
  methods: {
    update (dt) {
      this.$emit('input', format(dt, INTERNAL_FORMAT))
    },
    clear () {
      this.internalDate = null
      this.$emit('input', null)
    },
    setDate (value) {
      this.internalDate = value ? parse(value, INTERNAL_FORMAT, new Date()) : null
    }
  },
  mounted () {
    this.setDate(this.value)
  },
  watch: {
    value: function (newVal, oldVal) {
      if (!isEqual(parse(newVal, INTERNAL_FORMAT, new Date()), this.internalDate)) {
        this.setDate(newVal)
      }
    }
  },
  components: {
    Datepicker
  }
}
</script>
<style scoped>
/deep/ input[readonly] {
  background-color: transparent;
}
</style>
