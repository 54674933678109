import store from '@/store'

export default function (loadData) {
  return {
    beforeRouteEnter: (to, from, next) => {
      store.commit('set_loading_animation', true)
      loadData(to).then(response => {
        const data = response.data
        next(vm => vm.setData(data))
      })
        .finally(() => store.commit('set_loading_animation', false))
    },
    beforeRouteUpdate: function (to, from, next) { // Function declaration is necessary to access <this>
      store.commit('set_loading_animation', true)
      loadData(to).then(response => {
        this.setData(response.data)
        next()
      })
        .finally(() => store.commit('set_loading_animation', false))
    }
  }
}
