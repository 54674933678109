<template>
  <div>
    <div class="form-group row">
      <div class="col-lg-4">
        <input type="text"
        class="form-control"
        placeholder="Search PRN or Name"
        v-model="search_term">
      </div>
    </div>
    <grid-table :columns='columns'
      :options="table_options"
      :table-data="table_data">
      <router-link
        slot='id'
        slot-scope='props'
        :to="{name: 'RequestDetail', params:  {requestId: props.row.id, hospital: props.row.hospital }}"
        class="font-weight-bold">{{ props.row.id }}
      </router-link>
      <span
        slot='patient'
        slot-scope='props'>
        <router-link
          :to="{name: 'RequestPatientDetail', params: {hospital_id: props.row.patient.hospital_id, hospital: props.row.hospital }}">
          {{ props.row.patient.name }}
        </router-link> #{{ props.row.patient.hospital_id }} <span v-if="props.row.hospital_bed_number !== null">@{{ props.row.hospital_bed_number }}</span></span>
      <span slot="antibiotics" slot-scope='props'><span class="antibiotic-text" v-for="antibiotic in props.row.antibiotics">{{ antibiotic.antibiotic }} x {{ antibiotic.duration }}d</span></span>
      <span class="pharmacy_instruction"
            :class="props.row.pharmacy_instruction"
            slot="pharmacy_instruction" slot-scope="props">
        {{ props.row.pharmacy_instruction | snake_to_startcase }}
      </span>
      <span slot="ast_status" slot-scope="props">
        <span v-if="props.row.ast_status != 'Pending'">{{ props.row.ast_status }}</span>
      </span>
      <span slot="actions" slot-scope="props">
        <i v-if="user.permissions.all_permissions.includes('antibiotic_authorization.authorize_antibioticrequest')" v-b-modal.modal1 @click='processRequest(props.row.hospital, props.row.id)' class="fa fa-retweet text-info"></i>
      </span>
    </grid-table>
    <grid-pagination
      :page="page"
      :route-link="{ name: $route.name, query: $route.query }"
      :count="result_count">
    </grid-pagination>

    <b-modal no-fade id="modal1" ref='requestProcessModal' hide-footer size='lg' :title="'Process Antibiotic Request #' + currentRow.requestId">
      <request-process :params='currentRow' @process-finished='afterSuccessfulProcess()'></request-process>
    </b-modal>
  </div>
</template>

<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import GridTable from '@/components/shared/datatable/Grid'
import GridPagination from '@/components/shared/datatable/GridPagination'
import CreateRouterMixin from '@/common/routerMixin'
import RequestProcess from '@/components/a_authorization/RequestProcessM'
import { mapGetters, mapState } from 'vuex'
// import _ from 'lodash'

var getData = function (route) {
  return axios.get(`hospital/${route.params.hospital}/antibiotic_authorization/`,
    Object.assign({ params: route.query }, axiosConfig))
}

var routerMixin = CreateRouterMixin(getData)

export default {
  name: 'RequestList',
  components: {
    'grid-table': GridTable,
    'grid-pagination': GridPagination,
    'request-process': RequestProcess
  },
  mixins: [routerMixin],
  props: {
    page: {
      type: Number,
      default: 1
    }
  },
  data: function () {
    return {
      search_term: '',
      columns: ['id', 'request_time', 'patient', 'antibiotics', 'ast_status', 'actions', 'pharmacy_instruction'],
      table_data: [],
      result_count: 0,
      table_options: {
        headings: {
          id: '#',
          request_time: 'Time',
          ast_status: 'HIC review',
          pharmacy_instruction: 'Pharmacy Instruction'
        },
        dateColumns: ['request_time']
      },
      currentRow: { requestId: null, hospital: null }
    }
  },
  computed: {
    ...mapGetters([
      'currentHospital'
    ]),
    ...mapState([
      'user'
    ])
  },
  methods: {
    setData (data) {
      this.table_data = data.results
      this.result_count = data.count
    },
    processRequest (hospital, requestId) {
      this.currentRow = {
        hospital: hospital,
        requestId: requestId
      }
    },
    afterSuccessfulProcess () {
      this.$refs.requestProcessModal.hide()
      // We want to reflect the change in the grid. Just refresh it
      getData(this.$route).then(response => {
        this.setData(response.data)
      })
    }
  },
  watch: {
    search_term: function (newSearch, oldSearch) {
      var route = { name: this.$route.name, query: {} }

      // A number usually means hospital ID
      if (newSearch.match(/\d{4,10}/)) { route.query.patient__hospital_id = newSearch }

      // search syntax
      const antibioticList = this.$store.state.antibiotic_authorization_config.antibiotics
      if (antibioticList.some(item => newSearch === item)) {
        route.query.antibiotic = newSearch
      } else if (newSearch.match(/[A-Za-z]+/)) {
        route.query.search = newSearch
      }

      this.$router.push(route)
    }
  }
}

</script>
<style scoped>
  th {
    text-transform: uppercase;
  }
  span.antibiotic-text:after{
    content: ', ';
  }
  span.antibiotic-text:last-child:after{
    content: '';
  }
  span.pharmacy_instruction {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 500;
  }
  span.pharmacy_instruction.issue_drugs {
    color: green;
  }
  span.pharmacy_instruction.do_not_issue {
    color: red;
  }
</style>
