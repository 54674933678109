<template>
  <div>
    <table class="table table-sm table-bordered">
      <tbody>
        <template v-for="result in results">
          <tr v-for="(parameter, index) in result.parameters">
            <td class="result_date" v-if="index === 0" :rowspan="result.parameters.length" style="width: 10%;">{{ result.date | datetime }}</td>
            <td v-if="index === 0" :rowspan="result.parameters.length">{{ result.test }}</td>
            <td>{{ parameter.parameter }}</td>
            <td>{{ parameter.result }}</td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import CreateRouterMixin from '@/common/routerMixin'
// import _ from 'lodash'

const getData = function (route) {
  return axios.get(`hmis_functions/test-archives/${route.params.hospitalId}/`, axiosConfig)
}

const routerMixin = CreateRouterMixin(getData)

export default {
  name: 'CultureSearch',
  data: function () {
    return {
      results: []
    }
  },
  methods: {
    setData (data) {
      this.results = data
    }
  },
  mixins: [routerMixin]
}
</script>
<style scoped>
.table td {
  font-size: 12px;
  border: 1px solid #999;
  padding: 0 0.3rem!important;
}
</style>
