<template>
<router-view v-if="!loading"></router-view>
</template>

<script>
export default {
  name: 'app',
  data () {
    return {
      loading: true
    }
  },
  created () {
    Promise.all([
      this.$store.dispatch('load_application_config'),
      this.$store.dispatch('load_credentials')
    ]).finally(() => {
      this.loading = false
    })
  }
}
</script>
<style>

</style>
