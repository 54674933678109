<template>
<div>
<h4>Patient Details</h4>
    <dl>
      <dt>PRN</dt><dd>{{ hospital_id }}</dd>
      <dt>Name</dt><dd>{{ name }}</dd>
    </dl>
    <grid-table
      :table-data='device_monitoring'
      :options='device_monitoring_grid.options'
      :columns="device_monitoring_grid.columns">
      <router-link
        slot='document_type'
        slot-scope='props'
        :to="getLink(props.row)">
        {{ props.row.document_type | snake_to_startcase }}
      </router-link>
    </grid-table>
    <div>
      <button class='btn btn-link btn-sm' @click='create_device_monitoring_sheet'>+ Create Device Surveillance Sheet</button>
    </div>
</div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { mapGetters } from 'vuex'
import router from '@/router'
import GridTable from '@/components/shared/datatable/Grid'
import CreateRouterMixin from '@/common/routerMixin'
import { SheetFactory } from '@/components/icu_monitoring/models'

var getData = function (r) {
  return axios.get(`ic_documents/patients/${r.params.hospitalId}/`, axiosConfig)
    // If server responds with 404, redirect to the Patient Create View
    .catch(error => {
      if (error.response.status === 404) {
        router.push({ name: 'PatientEdit', query: { hospital_id: r.params.hospitalId, onSuccess: r.path } })
      }
    })
}

var routerMixin = CreateRouterMixin(getData)

export default {
  name: 'PatientDetail',
  mixins: [routerMixin],
  data: () => {
    return {
      name: null,
      hospital_id: null,
      patient_id: null,
      authorization_requests: [],
      device_monitoring: [],
      device_monitoring_grid: {
        columns: ['document_type', 'created', 'modified'],
        options: {
          dateColumns: ['created', 'modified']
        }
      }
    }
  },
  computed: {
    ...mapGetters(['currentHospital'])
  },
  methods: {
    setData (data) {
      this.name = data.name
      this.patient_id = data.id
      this.hospital_id = data.hospital_id
      this.device_monitoring = data.hic_documents
    },
    getLink (row) {
      const documentTypeMap = {
        device_monitoring: 'ICUSurveillanceSheet',
        surgical_site_monitoring: 'SurgeryMonitoringFormEdit',
        vae_chart: 'VAEFormEdit',
        covid_chart: 'CovidChartDetail'
      }
      return {
        name: documentTypeMap[row.document_type],
        params: {
          documentId: row.id,
          hospital: row.hospital,
          patientId: this.patient_id
        }
      }
    },
    create_device_monitoring_sheet () {
      const msg = 'Do you want to create a NEW sheet?'
      if (confirm(msg)) {
        axios.post(`ic_documents/hospital/${this.$route.params.hospital}/device_monitoring/`, {
          patient: this.patient_id,
          document_type: 'device_monitoring',
          content: new SheetFactory(),
          hospital: this.currentHospital.id
        }, axiosConfig).then(response => {
          router.push({ name: 'ICUSurveillanceSheet', params: { hospital: this.currentHospital.code, patientId: this.patient_id, documentId: response.data.id } })
        })
      }
    }
  },
  components: {
    'grid-table': GridTable
  }
}
</script>
<style scoped>
  span.antibiotic-text:after{
    content: ', ';
  }
  span.antibiotic-text:last-child:after{
    content: '';
  }
</style>
