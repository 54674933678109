<template>
  <div class="fs-field" :class="outer_class">
    <label>{{ label }}</label>
    <component :is="component"
       :value="value"
       v-bind="$attrs"
       @input="$emit('input', $event)"
    ></component>
  </div>
</template>
<script>
export default {
  name: 'FSCustomInput',
  props: [
    'label',
    'outer_class',
    'component',
    'value'
  ]
}
</script>
