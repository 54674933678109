import { inject } from '@vue/composition-api'
import _ from 'lodash'

export default function (attrs) {
  const fsvalidator = inject('fsValidator')
  // if schema path is firstLevel.0.secondLevel, vuelidate path is firstLevel.$each.0.secondLevel
  const fsVPath = 'value.' + attrs.fsLocation.split('.').map(str => {
    // replace <number> by $each.<number>
    // replaceAll would have been convenient
    return str.replace(/^(\d+)$/, '$each.$1')
  }).join('.')

  return _.get(
    fsvalidator(),
    fsVPath,
    { // Default Object
      $dirty: false,
      $invalid: false,
      $touch: () => {}
    })
}
