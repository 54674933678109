<template>
  <div class="fs-field" :class="outer_class">
    <label>{{ label }}</label>
    <input class='form-control'
           :type='type'
           :class="{'is-invalid': $fsV.$dirty && $fsV.$invalid}"
           @blur="$fsV.$touch()"
           :placeholder="placeholder"
           v-model='valueAccessor'
           v-bind='$attrs'
           >
  </div>
</template>
<script>

import FSValidationMixin from '../FSValidationMixin'

export default {
  name: 'FSTextInput',
  props: [
    'label',
    'type',
    'value',
    'placeholder',
    'outer_class'
  ],
  computed: {
    valueAccessor: {
      get () {
        return this.value
      },
      set (v) {
        if (this.type === 'number') {
          if (v !== '') {
            v = Number(v)
          } else {
            v = null
          }
        }
        this.$fsV.$touch()
        this.$emit('input', v)
      }
    }
  },
  mixins: [
    FSValidationMixin
  ]
}
</script>
<style scoped>
input.form-control.is-invalid {
  border-color: red!important;
  background-color: #FCE4EC;
}
</style>
