<template>
  <form novalidate @submit.prevent="validateAndSave()">
    <h4>Device Surveillance</h4>
    <div>
      <div class='d-inline-block p-2 mb-2 border bg-light'><strong>{{ patient.name }} - {{ patient.hospital_id }}</strong></div>
      <div class='d-inline-block p-2'><a :href="`${hmisBaseUrl}/Eximius/Consulting/Archives/Consulting_Archives.aspx?PRN=${patient.hospital_id}`" target='_blank'>Archives <i class='fa fa-external-link'></i> </a></div>
    </div>
    <FormSchema :schema="formSchema" :value="sheetx" ref="fs"></FormSchema>

    <b-modal ref="dateModal"
      @cancel="dailyParameterStartDate = null"
      title="Set the first day of monitoring">
      <div class="d-block text-center">
        <Datepicker v-model="dailyParameterStartDate"></Datepicker>
      </div>
    </b-modal>
    <button class='btn btn-primary'
      @click.prevent='validateAndSave()'>Save</button>
    <router-link :to="{ name: 'VAEFormNew', params: {patientId: patient.id}, query: { from_surveillance_sheet: $route.params.documentId } }"  class="btn btn-link">Create A VAE Sheet</router-link>
  </form>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
// import router from '@/router'
import Vue from 'vue'
import { ref, reactive, computed } from '@vue/composition-api'
import CreateRouterMixin from '@/common/routerMixin'
import _ from 'lodash'
import { formatISO, parseISO, addDays } from 'date-fns'
import Fahrenheit from '@/components/shared/fahrenheit.vue'
import OrganismSelect from '@/components/shared/custom_widgets/organismSelect.vue'
import FormSchema from '@/components/shared/form-schema/FormSchema'
import { SheetFactory } from '@/components/icu_monitoring/models'
import Datepicker from '@/components/shared/datepicker'
import router from '@/router'

var loadData = r => {
  return axios.get(`ic_documents/hospital/${r.params.hospital}/device_monitoring/${r.params.documentId}/`, axiosConfig)
}

var routerMixin = CreateRouterMixin(loadData)

const cultureSyncIndicator = Vue.component('culture-sync-indicator', {
  render () {
    const sampleIdString = 'Imported automatically - Sample ID ' + this.$attrs.value.sample_id
    return <div class="indicator">{
      this.$attrs.value.sample_id
        ? <div title={sampleIdString}><i class='fa fa-refresh text-info'></i></div>
        : null
    }</div>
  }
})

var DEFAULT_CVC = {
  inserted: null,
  removed: null,
  type: 'Central Venous Catheter',
  site: null,
  laterality: null,
  poa: false,
  remarks: ''
}

var DEFAULT_LOCATION = {
  location: null,
  transfer_in: null,
  transfer_out: null
}

var DEFAULT_URINARY_CATHETER = {
  type: 'Foley Catheter',
  inserted: null,
  removed: null,
  poa: false,
  remark: null
}

var DEFAULT_VENTILATION = {
  type: 'Endotracheal Intubation',
  inserted: null,
  removed: null,
  poa: false
}

var DEFAULT_ANTIBIOTIC = {
  antibiotic: null,
  started: null,
  stopped: null
}

var DEFAULT_CULTURE = {
  date: null,
  specimen: null,
  site: null,
  meta: {
    sample_id: null
  },
  organism: {
    code: null,
    name: null
  },
  remark: null
}

const SPECIMEN_LIST = ['blood', 'urine', 'csf', 'sputum', 'tracheal_aspirate', 'bronchoalveolar_lavage', 'ascitic_fluid', 'pleural_fluid', 'pus', 'tissue', 'stool', 'other']

function VentilationDay () {
  this.date = null
  this.max_temp = null
  this.hypotension_vasopressors = false
  this.diarrhea = false
  this.ventilator_mode = null
  this.fiO2 = null
  this.peep = null
  this.tlc = null
  this.events = null
}

export default {
  name: 'ICUSurveillanceSheet',
  mixins: [routerMixin],
  setup (props, context) {
    const locationChoices = computed(() => {
      return context.root.$store.state.issue_tracker_config.location_choices.map(optgroup => {
        return {
          label: optgroup.label,
          items: optgroup.locations
        }
      })
    })
    const ventilationModesChoices = computed(() => context.root.$store.state.ventilationModes)

    const antibioticOptions = computed(() => {
      return context.root.$store.state.antibiotic_list.map(i => {
        return {
          value: i,
          label: i
        }
      })
    })

    const fs = ref(null)
    const sheetInit = new SheetFactory()
    const sheetx = ref(sheetInit)

    const patient = ref({
      name: null,
      hospital_id: null
    })

    const currentRoute = computed(() => router.currentRoute)
    const currentHospital = computed(() => context.root.$store.getters.currentHospital)
    const hmisBaseUrl = computed(() => context.root.$store.getters.hmis_base_url)

    const formSchema = reactive([
      {
        type: 'textarea',
        label: 'Clinical Details',
        key: 'clinical_details',
        rows: 4
      },
      {
        type: 'grid',
        label: 'Locations',
        key: 'locations',
        row_default: () => _.clone(DEFAULT_LOCATION),
        row_schema: [
          {
            type: 'select',
            label: 'Location',
            key: 'location',
            optgroups: true,
            options: locationChoices,
            outer_class: 'field_container',
            validators: ['required']
          },
          {
            type: 'datepicker',
            label: 'Transfer In',
            key: 'transfer_in',
            validators: ['required']
          },
          {
            type: 'datepicker',
            label: 'Transfer Out',
            key: 'transfer_out'
          }
        ]
      },
      {
        type: 'grid',
        label: 'Daily parameters',
        key: 'daily_parameters',
        row_default: () => nextDailyParameter(),
        row_schema: [
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date',
            validators: ['required']
          },
          {
            type: 'custom-input',
            label: 'Max Temp (F)',
            key: 'max_temp',
            component: Fahrenheit
          },
          {
            type: 'select',
            label: 'Ventilation Mode',
            key: 'ventilator_mode',
            options: ventilationModesChoices,
            validators: ['required']
          },
          {
            type: 'number',
            label: 'FiO2(%)',
            key: 'fiO2'
          },
          {
            type: 'number',
            label: 'PEEP',
            key: 'peep'
          },
          {
            type: 'number',
            label: 'TLC',
            key: 'tlc'
          },
          {
            type: 'boolean',
            label: 'Vasopressors',
            key: 'hypotension_vasopressors'
          },
          {
            type: 'boolean',
            label: 'Diarrhea',
            key: 'diarrhea'
          },
          {
            type: 'text',
            label: 'Events',
            key: 'events'
          }
        ]
      },
      {
        type: 'grid',
        label: 'Central Venous Catheters',
        key: 'cvc',
        row_default: () => _.clone(DEFAULT_CVC),
        row_schema: [
          {
            type: 'select',
            label: 'CVC Type',
            key: 'type',
            options: [
              { label: 'Central Venous Catheter', value: 'Central Venous Catheter' },
              { label: 'Hemodialysis Catheter', value: 'Hemodialysis Catheter' }
            ],
            validators: ['required']
          },
          {
            type: 'select',
            label: 'Site',
            key: 'site',
            options: [
              { label: 'Subclavian', value: 'subclavian' },
              { label: 'Internal Jugular', value: 'internal_jugular' },
              { label: 'Femoral', value: 'femoral' },
              { label: 'brachial', value: 'brachial' }
            ],
            validators: ['required']
          },
          {
            type: 'select',
            label: 'Laterality',
            key: 'laterality',
            options: [
              { label: 'Right', value: 'right' },
              { label: 'Left', value: 'left' }
            ],
            validators: ['required']
          },
          {
            type: 'datepicker',
            key: 'inserted',
            label: 'Inserted on'
          },
          {
            type: 'datepicker',
            key: 'removed',
            label: 'Removed on'
          },
          {
            type: 'boolean',
            key: 'poa',
            label: 'Present on Admission'
          }
        ]
      },
      {
        type: 'grid',
        label: 'Mechanical Ventilation',
        key: 'ventilation',
        row_default: () => _.clone(DEFAULT_VENTILATION),
        row_schema: [
          {
            type: 'select',
            label: 'Ventilation Type',
            key: 'type',
            options: [
              { label: 'Endotracheal Intubation', value: 'Endotracheal Intubation' },
              { label: 'Tracheostomy', value: 'Tracheostomy' }
            ],
            validators: ['required']
          },
          {
            type: 'datepicker',
            key: 'inserted',
            label: 'Inserted on'
          },
          {
            type: 'datepicker',
            key: 'removed',
            label: 'Removed on'
          },
          {
            type: 'boolean',
            key: 'poa',
            label: 'Present on Admission'
          }
        ]
      },
      {
        type: 'grid',
        label: 'Urinary Catheters',
        key: 'urinary_catheters',
        row_default: () => _.clone(DEFAULT_URINARY_CATHETER),
        row_schema: [
          {
            type: 'select',
            label: 'TYpe',
            key: 'type',
            options: [
              { label: 'Foley Catheter', value: 'Foley Catheter' },
              { label: 'Suprapubic Catheter', value: 'Suprapubic Catheter' }
            ],
            validators: ['required']
          },
          {
            type: 'datepicker',
            key: 'inserted',
            label: 'Inserted on',
            validators: ['required']
          },
          {
            type: 'datepicker',
            key: 'removed',
            label: 'Removed on'
          },
          {
            type: 'boolean',
            key: 'poa',
            label: 'Present on Admission'
          },
          {
            type: 'text',
            key: 'remark',
            label: 'Remarks'
          }
        ]
      },
      {
        type: 'grid',
        key: 'antibiotics',
        label: 'Antibiotics',
        row_default: () => _.clone(DEFAULT_ANTIBIOTIC),
        row_schema: [
          {
            key: 'antibiotic',
            label: 'Antibiotic',
            type: 'select',
            options: antibioticOptions,
            validators: ['required']
          },
          {
            type: 'datepicker',
            label: 'Started',
            key: 'started'
          },
          {
            type: 'datepicker',
            label: 'Stopped',
            key: 'stopped'
          }
        ]
      },
      {
        type: 'grid',
        key: 'cultures',
        label: 'Cultures',
        row_default: () => _.clone(DEFAULT_CULTURE),
        row_label: cell => `${_.defaultTo(cell.specimen, '?')} - ${_.defaultTo(cell.organism.name, 'negative')}`,
        row_schema: [
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date',
            validators: ['required']
          },
          {
            type: 'select',
            label: 'Specimen',
            key: 'specimen',
            options: _.map(SPECIMEN_LIST, item => { return { value: item, label: item } })
          },
          {
            type: 'text',
            label: 'Site',
            key: 'site'
          },
          {
            type: 'custom-input',
            component: OrganismSelect,
            label: 'Organism',
            key: 'organism'
          },
          {
            type: 'text',
            label: 'Remarks',
            key: 'remark'
          },
          {
            type: 'custom-input',
            component: cultureSyncIndicator,
            label: 'Sync',
            key: 'meta'
          }
        ]
      },
      {
        type: 'textarea',
        label: 'Remarks',
        key: 'remarks',
        rows: 4
      }
    ])

    const dateModal = ref(null)
    const dailyParameterStartDate = ref(null)

    const getVentilationStartDate = async () => {
      dateModal.value.show()
      return new Promise((resolve, reject) => {
        context.root.$on('bv::modal::hidden', () => {
          if (dailyParameterStartDate.value) {
            resolve(dailyParameterStartDate.value)
          } else {
            reject()
          }
        })
      })
    }

    const nextDailyParameter = async () => {
      const a = new VentilationDay()
      if (sheetx.value.daily_parameters.length > 0 && _.last(sheetx.value.daily_parameters).date) {
        const nextDay = formatISO(addDays(parseISO(_.last(sheetx.value.daily_parameters).date), 1), { representation: 'date' })
        a.date = nextDay
      } else {
        a.date = await getVentilationStartDate()
      }
      return a
    }

    const setData = data => {
      sheetx.value = Object.assign(sheetx.value, data.content)
      patient.value = Object.assign(patient.value, data.patient)
    }

    const validateAndSave = async () => {
      fs.value.$v.$touch()
      if (fs.value.$v.$invalid) {
        context.root.$store.dispatch('display_notification', {
          message: 'Not saved. There are errors in your sheet. They are highlighted in red above',
          type: 'error'
        })
        return
      }
      await saveSheet()
    }

    const saveSheet = async () => {
      const data = {
        user: context.root.$store.state.user.id,
        document_type: 'device_monitoring',
        content: sheetx.value,
        patient: currentRoute.value.params.patientId,
        hospital: currentHospital.value.id
      }
      axios.put(`ic_documents/hospital/${currentRoute.value.params.hospital}/device_monitoring/${currentRoute.value.params.documentId}/`, data, axiosConfig)
        .then(response => {
          context.root.$store.dispatch('display_notification', {
            message: 'Successfully Saved'
          })
        })
    }

    return {
      fs,
      formSchema,
      sheetx,
      patient,
      dailyParameterStartDate,
      dateModal,
      setData,
      validateAndSave,
      hmisBaseUrl
    }
  },
  components: {
    FormSchema,
    Datepicker
  }
}
</script>
<style scoped>
@import '../shared/grid.css'
</style>
<style scoped>
.fs-container >>> .indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
}
</style>
