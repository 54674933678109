import axios from 'axios'
import router from '@/router'
export default {
  baseURL: '/api/',
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken'
}

// Multiple requests may be unauthorized, only the first one should go through
let loggedOutLock = false
const loginScreen = 'AppLogin'

axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 403 && router.currentRoute.name !== loginScreen && !loggedOutLock) {
      // Acquire lock
      router.push({ name: loginScreen, query: { next: window.location.pathname } })
      // Release the lock after 5 seconds
      setTimeout(() => { loggedOutLock = false }, 5000)
    }
    return Promise.reject(error)
  })
