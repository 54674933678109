import { isRef } from '@vue/composition-api'
export function unwrapReactiveSchema (schema) {
  /*
   * The top level component passes in ref objects
   * These need to be unwrapped to their values to pass to the downstream dumb components
   */
  return schema.map(cellSchema => {
    const clonedSchema = {}
    for (const propertyName in cellSchema) {
      clonedSchema[propertyName] = isRef(cellSchema[propertyName])
        ? cellSchema[propertyName].value
        : cellSchema[propertyName]
    }
    return clonedSchema
  })
}
