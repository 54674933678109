<template>
  <div class="form-list">
    <draggable :value="value"
       v-on:change="rearrange($event)"
       handle='.drag-handle'>
    <div v-for="(row, index) in rows" :key="row._id" :class="getRowClass(row)">
      <slot name="row" :row="row" :index="index" :onchange='onChange'>
      <span>{{ row }}</span>
      </slot>
      <div class='action_container'>
        <span><i class="fa fa-bars drag-handle" aria-hidden="true"></i></span>
        <span @click.prevent='removeRow(index)'><i class="fa fa-times-circle"></i></span>
      </div>
    </div>
    </draggable>
    <div class='mt-2'>
    <button class="btn btn-sm btn-link" @click.prevent="addRow">+ Add Row</button>
    </div>
  </div>
</template>
<script>
import _ from 'lodash'
import draggable from 'vuedraggable'
export default {
  name: 'FormList',
  props: {
    value: Array,
    default: [Function, Object],
    row_class: {
      type: String,
      default: 'row'
    }
  },
  components: {
    draggable
  },
  data () {
    return {
      rows: this.valToRow(this.value)
    }
  },
  computed: {
    rowsToValue () {
      return _.map(this.rows, (row) => {
        return _.omit(row, '_id')
      })
    },
    propUpdated () {
      if (_.isEqual(this.value, this.rowsToValue)) {
        return false
      }
      return true
    }
  },
  watch: {
    value (newVal, oldVal) {
      if (this.propUpdated) {
        this.rows = this.valToRow(newVal)
      }
    }
  },
  methods: {
    async addRow () {
      let a
      if (_.isFunction(this.default)) {
        a = await this.default()
      } else {
        // compatibility
        a = _.clone(this.default)
      }
      a._id = Symbol()
      this.rows.push(a)
      this.$emit('input', this.rowsToValue)
    },
    removeRow (index) {
      this.rows.splice(index, 1)
      this.$emit('input', this.rowsToValue)
    },
    rearrange (ev) {
      var element = this.rows[ev.moved.oldIndex]
      this.rows.splice(ev.moved.oldIndex, 1)
      this.rows.splice(ev.moved.newIndex, 0, element)

      // pass to the parent
      this.$emit('input', this.rowsToValue)
    },
    onChange () {
      this.$emit('input', this.rowsToValue)
    },
    valToRow (val) {
      return val.map(o => {
        const a = _.clone(o)
        a._id = Symbol()
        return a
      })
    },
    getRowClass (row) {
      const rowClasses = _.concat(
        _.defaultTo(this.row_class, []),
        _.defaultTo(row._row_class, []))
      return rowClasses.join(' ')
    }
  }
}
</script>
<style scoped>
.row {
  position: relative;
}
.action_container {
  color: #333;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  padding-left: 12px;
}

.action_container i {
  float: right;
  margin: 2px;
}

.fa-bars {
  cursor: pointer;
}
.sortable-ghost {
  opacity: 0.8;
  border: 4px dashed red;
}
</style>
