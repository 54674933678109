function SheetFactory () {
  this.clinical_details = null
  this.locations = []
  this.daily_parameters = []
  this.ventilation = []
  this.cvc = []
  this.urinary_catheters = []
  this.antibiotics = []
  this.cultures = []
  this.remarks = null
}

exports.SheetFactory = SheetFactory
