<template>
  <div class="pb-3"><span class="hospital_name"><i class="fa fa-h-square"> </i> {{ currentHospital.name }}</span> <router-link class="small" :to="{ name: 'HospitalSelector' }">[change]</router-link></div>
</template>
<script>
import { mapGetters } from 'vuex'
export default {
  name: 'HospitalSwitcher',
  computed: {
    ...mapGetters(['currentHospital'])
  }
}
</script>
<style scoped>
.hospital_name {
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    padding: 2px 5px;
    color: #d81b60;
}
</style>
