<template>
  <app-frame>
    <div slot="screen" class="col-md-4 card p-5 mt-5 mx-auto">
        <form>
            <h4>Sahyadri HIC Login</h4>
            <div class="form-group">
                <label for="email">Email address</label>
                <input type="email" class="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email" v-model="email">
            </div>
            <div class="form-group">
                <label for="password">Password</label>
                <input type="password" class="form-control" id="password" placeholder="Password" v-model="password">
            </div>
            <div v-if="message" class="alert alert-danger">{{ message }}</div>
            <button type="submit" class="btn btn-primary" v-on:click.stop.prevent="login()">Log in</button>
        </form>
    </div>
  </app-frame>
</template>
<script>
import router from '@/router'
import AppFrame from '@/components/AppFrame'
export default {
  name: 'AppLogin',
  components: {
    'app-frame': AppFrame
  },
  data () {
    return {
      email: null,
      password: null,
      message: null
    }
  },
  computed: {
    next_path () {
      if (this.$route.query.next) {
        return this.$route.query.next
      }
      return '/app/select_hospital?autodetect=1'
    }
  },
  methods: {
    login () {
      const nextPath = this.next_path
      this.$store.dispatch('login_user', {
        email: this.email,
        password: this.password
      })
        .then((response) => {
          this.$store.dispatch('display_notification', {
            message: 'Successfully logged in'
          })
          this.$store.dispatch('load_application_config').then(() => {
            router.push({ path: nextPath })
          })
        })
        .catch(error => {
          this.$store.dispatch('display_notification', {
            message: error.response.data.message,
            type: 'error'
          })
        })
    }
  }
}
</script>
