<template>
  <div>
    <h4>Antibiotic Authorization Statistics</h4>
    <ul class="list-unstyled">
      <li v-for="dt in MonthList"><router-link :to="{ name: 'RequestStatistics', params:{'hospital': currentHospital.code, 'year': dt.getFullYear(), 'month': dt.getMonth() + 1 }}">{{ dateToLabel(dt) }}</router-link></li>
    </ul>

  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { subMonths, startOfMonth, format } from 'date-fns'
export default {
  name: 'RequestStatisticsIndex',
  data: function () {
    var StartDate = new Date(2017, 10, 1)
    var pointer = startOfMonth(new Date())
    var MonthList = []
    while (StartDate <= pointer) {
      MonthList.push(pointer)
      pointer = subMonths(pointer, 1)
    }
    return {
      MonthList: MonthList
    }
  },
  computed: {
    ...mapGetters(['currentHospital'])
  },
  methods: {
    dateToLabel: function (dt) {
      return format(dt, 'MMMM yyyy')
    }
  }
}
</script>
