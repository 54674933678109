<template>
  <div class="form1">
    <form novalidate @submit.prevent="validateAndSave()">
      <h4>VAE Chart</h4>
      <form-control label="Patient">
        <p class="font-weight-bold d-inline-flex p-2 bg-light form-control-plaintext">{{ patient.name }} (#{{ patient.hospital_id }})</p>
      </form-control>
      <div class="row">
        <div class="card col-md-4 m-3 vae_calculations">
          <div class="card-body">
            <h5 class="card-title">Calculated Events</h5>
            <DetailRender
              :list="vDatesRenderConfig"
              :list-data="vDates">
            </DetailRender>
          </div>
        </div>
      </div>
      <FormSchema :schema="formSchema" :value="sheet" ref="fs"></FormSchema>
      <button class='mt-5 btn btn-primary'
              @click.prevent='validateAndSave()'>Save</button>
      <button class='mt-5 btn btn-info'
              @click.prevent='syncSheet()'>Sync</button>
    </form>
    <b-modal ref="dateModal"
      @cancel="useVentilationStartDate = null"
      title="Set the first day of ventilation">
      <div class="d-block text-center">
        <Datepicker v-model="useVentilationStartDate"></Datepicker>
      </div>
    </b-modal>
    <pre>
    </pre>
  </div>
</template>

<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import CreateRouterMixin from '@/common/routerMixin'
import _ from 'lodash'
import FormControlWrapper from '@/components/shared/form_group'
import OrganismSelect from '@/components/shared/custom_widgets/organismSelect.vue'
import FormSchema from '@/components/shared/form-schema/FormSchema'
import Datepicker from '@/components/shared/datepicker.vue'
import DetailRender from '@/components/shared/custom_widgets/detailRender.vue'
import { ref, reactive, computed, onMounted, provide, inject } from '@vue/composition-api'
import { format, parseISO, formatISO, addDays, differenceInCalendarDays, isWithinInterval } from 'date-fns'
import router from '@/router'
import Vue from 'vue'

var loadData = route => {
  if (route.matched.some(record => record.meta.edit)) {
    return axios.get(`ic_documents/hospital/${route.params.hospital}/patient_documents/${route.params.documentId}/`, axiosConfig)
  } else {
    return axios.get(`patients/${route.params.patientId}/`, axiosConfig)
  }
}

var routerMixin = CreateRouterMixin(loadData)

const SPECIMEN_LIST = ['blood', 'urine', 'csf', 'sputum', 'tracheal_aspirate', 'bronchoalveolar_lavage', 'pus', 'tissue', 'stool', 'other']

const vaeIndicator = Vue.component('vae-indicator', {
  setup (props, context) {
    const ventilatorEventDates = inject('vaeEventDates')
    const cellClass = computed(() => ventilatorEventDates.value.includes(context.attrs.rowContext.date) ? 'text-danger' : '')
    return {
      ventilatorEventDates,
      cellClass
    }
  },
  render () {
    return <div class='vae_date_indicator'>{ this.cellClass
      ? <i title='VAE event occurred on this date' class="text-danger fa fa-exclamation-circle"> </i>
      : ''
    }</div>
  }
})

function Antibiotic () {
  this.antibiotic = null
  this.started = null
  this.stopped = null
}

function VentilationDay () {
  this.date = null
  this.ventilator_mode = null
  this.fiO2 = null
  this.peep = null
  this.leucocytosis_or_leucopenia = false
  this.febrile_or_hypothermic = false
  this.cxr = null
  this.events = null
}

function CultureEvent () {
  this.date = null
  this.specimen = null
  this.site = null
  this.organism = {
    code: null,
    name: null
  }
  this.remark = null
  this.meets_pvap_criterion = false
}

function RadiographStudy () {
  this.date = null
  this.study_type = null
  this.study_findings = null
}

function VAEPathogen () {
  this.organism = {
    name: null,
    code: null
  }
  this.mdro = false
}

export default {
  name: 'VAESheet',
  mixins: [
    routerMixin
  ],
  setup (props, context) {
    const locationChoices = computed(() => {
      return context.root.$store.state.issue_tracker_config.location_choices.map(optgroup => {
        return {
          label: optgroup.label,
          items: optgroup.locations
        }
      })
    })
    const antibioticOptions = computed(() => {
      return context.root.$store.state.antibiotic_list.map(i => {
        return {
          value: i,
          label: i
        }
      })
    })
    const departmentChoices = computed(() => context.root.$store.state.clinical_departments.map(i => {
      return {
        label: _.startCase(i),
        value: i
      }
    }))
    const ventilationModesChoices = computed(() => context.root.$store.state.ventilationModes)

    const dateModal = ref(null)
    const fs = ref(null)
    const useVentilationStartDate = ref(null)
    const currentRoute = computed(() => router.currentRoute)
    const currentHospital = computed(() => context.root.$store.getters.currentHospital)

    const getVentilationStartDate = async () => {
      dateModal.value.show()
      return new Promise((resolve, reject) => {
        context.root.$on('bv::modal::hidden', () => {
          if (useVentilationStartDate.value) {
            resolve(useVentilationStartDate.value)
          } else {
            reject()
          }
        })
      })
    }

    const nextVentilationDay = async () => {
      const a = new VentilationDay()
      if (sheet.vae_parameters.length > 0 && _.last(sheet.vae_parameters).date) {
        const nextDay = formatISO(addDays(parseISO(_.last(sheet.vae_parameters).date), 1), { representation: 'date' })
        a.date = nextDay
      } else {
        a.date = await getVentilationStartDate()
      }
      return a
    }

    const formSchema = reactive([
      {
        type: 'textarea',
        label: 'Clinical Details',
        key: 'clinical_details',
        rows: 4
      },
      {
        type: 'datepicker',
        label: 'Date of Admission',
        key: 'date_of_admission',
        outer_class: 'field_container',
        validators: ['required']
      },
      {
        type: 'select',
        label: 'Location',
        key: 'location',
        optgroups: true,
        options: locationChoices,
        outer_class: 'field_container',
        validators: ['required']
      },
      {
        type: 'select',
        label: 'Clinical Department',
        key: 'clinical_department',
        options: departmentChoices,
        outer_class: 'field_container',
        validators: ['required']
      },
      {
        type: 'grid',
        label: 'VAE parameters',
        key: 'vae_parameters',
        row_default: () => nextVentilationDay(),
        row_label: cell => cell.date,
        row_schema: [
          {
            type: 'custom-input',
            component: vaeIndicator,
            label: 'VAE?',
            vDates: vDates,
            cell_class: 'app-md-half'
          },
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date',
            validators: ['required']
          },
          {
            type: 'select',
            label: 'Ventilation Mode',
            key: 'ventilator_mode',
            options: ventilationModesChoices,
            validators: ['required']
          },
          {
            type: 'number',
            label: 'PEEP',
            key: 'peep'
          },
          {
            type: 'number',
            label: 'FiO2(%)',
            key: 'fiO2'
          },
          {
            type: 'boolean',
            label: 'T>38/<36',
            key: 'febrile_or_hypothermic'
          },
          {
            type: 'boolean',
            label: 'WBC>12K/<4K',
            key: 'leucocytosis_or_leucopenia'
          },
          {
            type: 'text',
            label: 'Chest Xray',
            key: 'cxr'
          },
          {
            type: 'text',
            label: 'Events',
            key: 'events'
          }

        ]
      },
      {
        type: 'grid',
        key: 'antibiotics',
        label: 'Antibiotics',
        row_default: () => new Antibiotic(),
        row_schema: [
          {
            key: 'antibiotic',
            label: 'Antibiotic',
            type: 'select',
            options: antibioticOptions,
            validators: ['required']
          },
          {
            type: 'datepicker',
            label: 'Started',
            key: 'started'
          },
          {
            type: 'datepicker',
            label: 'Stopped',
            key: 'stopped'
          }
        ]
      },
      {
        type: 'grid',
        key: 'cultures',
        label: 'Cultures',
        row_default: () => new CultureEvent(),
        row_schema: [
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date',
            validators: ['required']
          },
          {
            type: 'select',
            label: 'Specimen',
            key: 'specimen',
            options: _.map(SPECIMEN_LIST, item => { return { value: item, label: item } })
          },
          {
            type: 'text',
            label: 'Site',
            key: 'site'
          },
          {
            type: 'custom-input',
            component: OrganismSelect,
            label: 'Organism',
            key: 'organism'
          },
          {
            type: 'text',
            label: 'Remarks',
            key: 'remark'
          },
          {
            type: 'boolean',
            label: 'PVAP criteria',
            key: 'meets_pvap_criterion'
          }
        ]
      },
      {
        type: 'grid',
        key: 'radiographs',
        label: 'Radiology',
        row_default: () => new RadiographStudy(),
        row_schema: [
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date'
          },
          {
            type: 'select',
            label: 'Study Type',
            key: 'study_type',
            options: [
              { label: 'Chest Xray', value: 'cxr' },
              { label: 'CT Chest', value: 'ct_chest' },
              { label: 'Other', value: 'other' }
            ]
          },
          {
            type: 'text',
            label: 'Study Findings',
            key: 'study_findings',
            cell_class: 'col-md-8'
          }
        ]
      },
      {
        type: 'grid',
        key: 'pathogens',
        label: 'Causative Pathogen',
        row_default: () => new VAEPathogen(),
        row_label: cell => cell.organism.name ? cell.organism.name : '?',
        row_schema: [
          {
            type: 'custom-input',
            component: OrganismSelect,
            label: 'Organism',
            key: 'organism',
            cell_class: 'col-md-3'
          },
          {
            type: 'boolean',
            key: 'mdro',
            label: 'MDRO pathogen',
            cell_class: 'col-md-3'
          }
        ]
      },
      {
        type: 'datepicker',
        key: 'outcome.death',
        label: 'Death',
        outer_class: 'field_container'
      },
      {
        type: 'datepicker',
        key: 'outcome.discharged',
        label: 'Date of Discharge',
        outer_class: 'field_container'
      },
      {
        type: 'boolean',
        key: 'outcome.event_contributed_to_death',
        label: 'Event contributed to death?',
        outer_class: 'field_container'
      },
      {
        type: 'textarea',
        label: 'Root Cause Analysis',
        key: 'rca',
        rows: 4
      },
      {
        type: 'select',
        label: 'VAE Type',
        key: 'chart_review.vae_category',
        options: [
          { label: 'No VAE', value: 'no_vae' },
          { label: 'VAE', value: 'vae' },
          { label: 'IVAC', value: 'ivac' },
          { label: 'PVAP', value: 'pvap' }
        ],
        outer_class: 'field_container'
      },
      {
        type: 'boolean',
        label: 'Clinically defined pneumonia',
        key: 'chart_review.clinically_defined_pneumonia',
        outer_class: 'field_container'
      },
      {
        type: 'boolean',
        label: 'Present on admission',
        key: 'chart_review.present_on_admission',
        outer_class: 'field_container'
      }
    ])

    let sheet = reactive({
      clinical_details: null,
      date_of_admission: null,
      location: null,
      clinical_department: null,
      vae_parameters: [],
      antibiotics: [],
      cultures: [],
      radiographs: [],
      secondary_bloodstream_infection: false,
      pathogens: [],
      remarks: null,
      chart_review: {
        vae_category: null,
        clinically_defined_pneumonia: false,
        present_on_admission: false
      },
      rca: null,
      post_procedure: {
        date: null,
        name: null,
        icd_code: null
      },
      outcome: {
        death: null, // Date
        discharged: null, // Date
        event_contributed_to_death: false
      },
      metadata: {
        surveillance_sheet: null
      }
    })

    const patient = ref({ name: null, hospital_id: null })

    function * paramWindowGenerator () {
      for (let i = 2; i < sheet.vae_parameters.length - 1; i++) {
        yield sheet.vae_parameters.slice(i - 2, i + 2)
      }
    }

    const isOnMechanicalVentilation = val => _.startsWith(val, 'invasive_ventilation')

    const vaeEventWindows = computed(() => {
      const eventWindows = {
        peep: [],
        fiO2: []
      }

      for (const paramWindow of paramWindowGenerator()) {
        // check if mechanical ventilation is on for all days
        const windowOnMechVentilation = paramWindow.every(day => isOnMechanicalVentilation(day.ventilator_mode))
        if (!windowOnMechVentilation) {
          // patient is off ventilator for some of these days
          continue
        }

        const [p0, p1, p2, p3] = paramWindow.map(ev => Math.max(ev.peep, 5))
        const [pX, pY] = [Math.max(p0, p1), Math.min(p2, p3)]
        if (pY - pX >= 3) {
          eventWindows.peep.push(paramWindow)
        }

        const [f0, f1, f2, f3] = paramWindow.map(ev => Math.max(ev.fiO2, 40))
        const [fX, fY] = [Math.max(f0, f1), Math.min(f2, f3)]
        if (fY - fX >= 20) {
          eventWindows.fiO2.push(paramWindow)
        }
      }
      return eventWindows
    })

    const hasQAD = paramWindow => {
      return sheet.antibiotics.some(antibiotic => {
        if (!antibiotic.antibiotic || antibiotic.started === null || antibiotic.stopped === null) {
          return false
        }
        const started = parseISO(antibiotic.started)
        const stopped = parseISO(antibiotic.stopped)

        const paramWindowInterval = {
          start: parseISO(paramWindow[0].date),
          end: parseISO(paramWindow[3].date)
        }
        const antibioticsMoreThan4Days = differenceInCalendarDays(stopped, started) >= 4
        const antibioticStartedWithinInterval = isWithinInterval(started, paramWindowInterval)
        return antibioticStartedWithinInterval && antibioticsMoreThan4Days
      })
    }

    const ivacEventWindows = computed(() => {
      const evWindows = _.concat(vaeEventWindows.value.peep, vaeEventWindows.value.fiO2)
      return evWindows.filter(paramWindow => {
        const evidenceInfection = paramWindow.some(el => el.febrile_or_hypothermic) || paramWindow.some(el => el.leucocytosis_or_leucopenia)
        return evidenceInfection && hasQAD(paramWindow)
      })
    })

    const pvapEventWindows = computed(() => {
      return ivacEventWindows.value.filter(ivacWindow => {
        return meetsPVAPCriterion(ivacWindow)
      })
    })

    const meetsPVAPCriterion = paramWindow => {
      return sheet.cultures.some(culture => {
        const cultureDate = parseISO(culture.date)
        const paramWindowInterval = {
          start: parseISO(paramWindow[0].date),
          end: parseISO(paramWindow[3].date)
        }
        return culture.meets_pvap_criterion && isWithinInterval(cultureDate, paramWindowInterval)
      })
    }

    const eventWindowFormat = eventWindow => eventWindow.map(w => format(parseISO(w[2].date), 'd MMM yyy')).join(', ')

    const vDates = computed(() => {
      return {
        vae_peep: eventWindowFormat(vaeEventWindows.value.peep),
        vae_fiO2: eventWindowFormat(vaeEventWindows.value.fiO2),
        ivac: eventWindowFormat(ivacEventWindows.value),
        pvap: eventWindowFormat(pvapEventWindows.value)
      }
    })

    const vaeWindowsToDate = arr => arr.map(w => w[2].date)

    const vaeEventDates = computed(() => {
      return _.union(
        vaeWindowsToDate(vaeEventWindows.value.fiO2),
        vaeWindowsToDate(vaeEventWindows.value.peep)
      )
    })
    provide('vaeEventDates', vaeEventDates)

    const vDatesRenderConfig = [
      { label: 'PEEP Events', value: 'vae_peep' },
      { label: 'FiO2 Events', value: 'vae_fiO2' },
      { label: 'IVAC Events', value: 'ivac' },
      { label: 'PVAP Events', value: 'pvap' }
    ]

    const syncSheet = async () => {
      const msg = 'This will overwrite existing entries in the sheet'
      if (!confirm(msg)) {
        return false
      }

      const url = `ic_documents/hospital/${currentRoute.value.params.hospital}/patient_documents/${sheet.metadata.surveillance_sheet}/`
      const response = await axios.get(url, axiosConfig)
      sheet.vae_parameters = response.data.content.daily_parameters.map(param => {
        const v = new VentilationDay()
        return _.assign(
          v,
          _.pick(param, ['date', 'ventilator_mode', 'fiO2', 'peep']),
          {
            febrile_or_hypothermic: param.max_temp && (param.max_temp >= 38.0 || param.max_temp <= 36.0),
            leucocytosis_or_leucopenia: param.tlc && (param.tlc <= 4000 || param.tlc >= 12000)
          }
        )
      })

      sheet.cultures = response.data.content.cultures.map(p => {
        return Object.assign(new CultureEvent(), _.pick(p, ['date', 'site', 'specimen', 'organism', 'remark']))
      })

      sheet.antibiotics = response.data.content.antibiotics.map(p => {
        return Object.assign(new Antibiotic(), p)
      })
    }

    onMounted(() => {
      if (currentRoute.value.query.from_surveillance_sheet) {
        sheet.metadata.surveillance_sheet = currentRoute.value.query.from_surveillance_sheet
      }
    })

    const setData = data => {
      if (currentRoute.value.meta.edit) {
        sheet = Object.assign(sheet, data.content)
        patient.value = Object.assign(patient.value, data.patient)
      } else {
        patient.value = Object.assign(patient.value, _.pick(data, ['id', 'name', 'hospital_id']))
      }
    }

    const validateAndSave = async () => {
      fs.value.$v.$touch()
      if (fs.value.$v.$invalid) {
        context.root.$store.dispatch('display_notification', {
          message: 'Not saved. There are errors in your sheet. They are highlighted in red above',
          type: 'error'
        })
        return
      }
      saveSheet()
    }
    const saveSheet = async () => {
      const data = {
        document_type: 'vae_chart',
        content: sheet,
        patient: currentRoute.value.params.patientId,
        hospital: currentHospital.value.id
      }
      const formConfig = {
        put: {
          url: `ic_documents/hospital/${currentRoute.value.params.hospital}/patient_documents/${currentRoute.value.params.documentId}/`,
          method: 'put'
        },
        post: {
          url: `ic_documents/hospital/${currentRoute.value.params.hospital}/patient_documents/`,
          method: 'post'
        }
      }
      const pick = currentRoute.value.meta.edit ? formConfig.put : formConfig.post
      const response = await axios[pick.method](pick.url, data, axiosConfig)
      context.root.$store.dispatch('display_notification', {
        message: 'Successfully Saved'
      })
      if (!currentRoute.value.meta.edit) {
        router.push({ name: 'VAEFormEdit', params: { hospital: currentHospital.value.code, hospitalId: currentRoute.value.params.hospitalId, documentId: response.data.id } })
      }
    }

    return {
      formSchema,
      patient,
      sheet,
      useVentilationStartDate,
      dateModal,
      fs,
      vaeEventWindows,
      ivacEventWindows,
      vDates,
      vDatesRenderConfig,
      vaeEventDates,
      syncSheet,
      validateAndSave,
      setData
    }
  },
  components: {
    'form-control': FormControlWrapper,
    FormSchema,
    Datepicker,
    DetailRender
  }
}
</script>

<style scoped>
@media (min-width: 768px) {
  .form1 >>> .field_container {
    width: 33%!important;
    padding-right: 20px;
  }
  .form1 >>> .col-md-6 {
    padding: 0px;
  }

  .form1 >>> .app-md-half {
    flex: 0 0 4.166666%;
    max-width: 4.166666%;
  }

  .form1 >>> .vae_date_indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 35px;
  }

}

.vae_calculations {
  background-color: #fff9c4;
}
</style>
