<template>
  <div>
    <h4>
      Antibiotic Authorization Request
    </h4>
    <form novalidate @submit.prevent="validateAndSave()">
      <form-control label="Patient">
        <p slot class="form-control-plaintext">{{ patient.name }} (#{{ patient.hospital_id }})</p>
      </form-control>
      <form-control label="Bed Number"
                    invalid-feedback="Required">
        <input type="text" v-model="hospital_bed_number"
                           v-validate="'required'"
                           name="hospital_bed_number"
                           :class="{'form-control': true, 'is-invalid': errors.has('hospital_bed_number')}">
      </form-control>
      <form-control label="Requesting Consultant"
                    invalid-feedback="Required">
        <input type="text" v-model="clinician"
                           v-validate="'required'"
                           name="clinician"
                           :class="{'form-control': true, 'is-invalid': errors.has('clinician')}">
      </form-control>
      <form-control label="Indication"
                    invalid-feedback="Required">
        <select class="form-control form-control-sm"
                name="indication_category"
                v-model="indication_category"
                v-validate="'required'"
                :class="{'is-invalid':errors.has('indication_category')}">
          <option></option>
          <option value="culture_appropriate">Culture Appropriate</option>
          <option value="empirical">Empiric Therapy</option>
        </select>
        <div v-if="indication_category === 'empirical'" class="text-danger small">Important: Before starting/escalating antibiotics, please collect blood cultures / other specimen cultures</div>
      </form-control>
      <form-control label="Clinical Details"
                    :col=6
                    invalid-feedback="Required">
        <textarea v-model="clinical_details"
                  name="clinical_details"
                  v-validate="'required'"
                  :class="{'is-invalid': errors.has('clinical_details')}"
                  rows=4 placeholder="Example: Influenza ARDS with Acinetobacter ventilator associated pneumonia"
                         class="form-control"></textarea>
      </form-control>
      <p><label>Antibiotics</label></p>
      <div class="row text-center mb-2">
        <div class="col-md-4">Antibiotic</div>
        <div class="col-md-4">Dose</div>
        <div class="col-md-3">Duration (days)</div>
      </div>
      <template v-for="(row, index) in antibiotics">
        <div class="form-group row" :key="row.id">
          <div class="col-md-4 mb-3">
            <select v-bind:name="'antibiotic-' + index"
               v-model="row.antibiotic"
               v-validate="'required'"
               placeholder="Antibiotic"
               :class="{'form-control': true, 'is-invalid': errors.has('antibiotic-' + index)}">
              <option v-for="choice in antibiotic_choices"
                      v-bind:value="choice.value"
                      :key="choice.value">
              {{ choice.text }}
              </option>
            </select>
            <div class="invalid-feedback">
              Required
            </div>
          </div>
          <div class="col-md-4 mb-3">
            <input type="text"
                   v-model="row.dose"
                   v-validate="'required'"
                   :class="{'form-control': true, 'is-invalid': errors.has('dose-'+index)}"
                   placeholder="Dose"
                   v-bind:name="'dose-' + index">
            <div class="invalid-feedback">
              Required
            </div>
          </div>
          <div class="col-md-3 mb-3">
            <input type="number"
                   v-model.number="row.duration"
                   v-validate="'required|numeric'"
                   :class="{'form-control': true, 'is-invalid': errors.has('duration-' + index)}"
                   placeholder="Duration"
                   v-bind:name="'duration-' + index">
            <div class="invalid-feedback">
              Required and must be a number
            </div>
          </div>
          <div class="col-md-1 mb-3">
            <span class="fa-stack fa-lg row_delete"
                  v-on:click="removeAntibiotic(index)">
              <i class="fa fa-times-circle"></i>
            </span>
          </div>
        </div>
      </template>
      <div class="mb-3">
        <button
           id="add_antibiotic_button"
           class="btn btn-outline-secondary btn-sm"
           v-on:click.prevent.stop="addAntibiotic()">
          <i class="fa fa-plus"></i> Add antibiotic
        </button>
      </div>
      <button class="btn btn-primary">Save</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import router from '@/router'
import { mapGetters } from 'vuex'
import CreateRouterMixin from '@/common/routerMixin'
import _ from 'lodash'
import FormControlWrapper from '@/components/shared/form_group'

var loadData = route => {
  if (route.matched.some(record => record.meta.edit)) {
    return axios.get(`hospital/${route.params.hospital}/antibiotic_authorization/${route.params.requestId}/`, axiosConfig)
  } else {
    return axios.get(`patients/${route.params.patientId}/`, axiosConfig)
  }
}

var routerMixin = CreateRouterMixin(loadData)

export default {
  name: 'RequestNew',
  mixins: [routerMixin],
  data () {
    return {
      patient: {
        id: null,
        hospital_id: null,
        name: null
      },
      hospital_bed_number: null,
      clinician: null,
      clinical_details: null,
      indication_category: null,
      antibiotics: [{
        id: 0,
        antibiotic: null,
        dose: '',
        duration: null
      }],
      nextAntibioticRowId: 1,
      consultants: []
    }
  },
  methods: {
    setPatientData (data) {
      _.assign(this.patient, _.pick(data, ['id', 'hospital_id', 'name']))
    },
    setData (data) {
      if (this.$route.meta.edit === false) {
        this.setPatientData(data)
        return
      }
      _.assign(this, _.pick(data, ['patient', 'clinician', 'hospital_bed_number', 'indication_category', 'clinical_details', 'antibiotics']))
    },
    addAntibiotic () {
      this.antibiotics.push({
        id: this.nextAntibioticRowId++,
        antibiotic: null,
        dose: '',
        duration: null
      })
    },
    removeAntibiotic (index) {
      this.antibiotics.splice(index, 1)
    },
    searchConsultants (query) {
      const qString = query.replace(/^dr\s*/gi, '')
      axios.get('antibiotic_authorization/consultants/?search=' + qString, axiosConfig).then(response => {
        this.consultants = response.data.slice(0, 10).map(item => {
          return 'Dr ' + item.name
        })
      })
    },
    validateAndSave () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveRequest()
        }
        if (!result) {
          this.$store.dispatch('display_notification', {
            message: 'Not saved. There are errors in your sheet. They are highlighted in red',
            type: 'error'
          })
        }
      })
    },
    saveRequest () {
      var data = _.pick(this, ['clinician', 'hospital_bed_number', 'indication_category', 'clinical_details'])
      data.patient_id = this.patient.id
      data.antibiotics = _.map(this.antibiotics, function (o) {
        return _.omit(o, 'id')
      })

      const axiosCall = isEdit => {
        return isEdit === true
          ? axios.put(`hospital/${this.currentHospital.code}/antibiotic_authorization/${this.$route.params.requestId}/`, data, axiosConfig)
          : axios.post(`hospital/${this.currentHospital.code}/antibiotic_authorization/`, data, axiosConfig)
      }
      axiosCall(this.$route.meta.edit).then(response => {
        router.push({ name: 'RequestDetail', params: { hospital: this.currentHospital.code, requestId: response.data.id } })
      }).catch(error => {
        console.log(error)
      })
    }
  },
  computed: {
    ...mapGetters(['currentHospital', 'antibiotic_choices']),
    user () {
      return this.$store.state.user
    }
  },
  components: {
    'form-control': FormControlWrapper
  }
}

</script>

<style scoped>
.row_delete {
  color: #9e9e9e;
}

.row_delete:hover {
  color: #F44336;
}

.is-invalid ~ .invalid-feedback {
  display: block;
}
</style>
