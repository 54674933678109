<template>
  <form novalidate @submit.prevent="validateAndSave()">
    <h4>Surgical Surveillance</h4>
    <div v-if='sheet.metadata.status === "PENDING"' class="alert alert-warning" role="alert">
      Warning: This auto-imported form is <b>NOT YET APPROVED</b>. Any data entered may be discarded.
      <button @click.prevent="changeStatus" class="btn btn-link">Click here to approve</button>
    </div>
    <form-control label="Patient">
      <p class="font-weight-bold d-inline-flex p-2 bg-light form-control-plaintext">{{ patient.name }} (#{{ patient.hospital_id }})</p>
    </form-control>
    <form-control label="Surgery">
      <input type="text" v-model="sheet.surgery"
                         v-validate="'required'"
                         name="sheet.surgery"
                         :class="{'form-control': true, 'is-invalid': errors.has('sheet.surgery')}">
    </form-control>

    <form-control label="Surgery Classification"
                  invalid-feedback="Required">
      <select class="form-control form-control-sm"
              name="sheet.surgery_class"
              v-model="sheet.surgery_class"
              v-validate="'required'"
              :class="{'is-invalid':errors.has('sheet.surgery_class')}">
        <option></option>
        <option value="clean">Clean</option>
        <option value="clean_contaminated">Clean-Contaminated</option>
        <option value="contaminated">Contaminated</option>
        <option value="dirty">Dirty</option>
      </select>
    </form-control>
    <form-control label="Clinical Details"
                  :col=6
                  invalid-feedback="Required">
      <textarea v-model="sheet.clinical_details"
                name="sheet.clinical_details"
                rows=4
                class="form-control"></textarea>
    </form-control>
    <div class="form-row">
      <div class="col-md-3">
        <label>Surgery Start</label>
        <datetimepicker :value.sync='sheet.start'></datetimepicker>
      </div>
      <div class="col-md-3 offset-md-1">
        <label>Surgery End</label>
        <datetimepicker :value.sync='sheet.end'></datetimepicker>
      </div>
      <div class="col-md-3">
        <label>Surgery Duration</label>
        <input v-if="surgery_duration" type='text' class='form-control-plaintext' v-model='surgery_duration.hours + ":" +  surgery_duration.minutes'>
      </div>
    </div>
    <form-control label="Surgeon">
      <input type="text" v-model="sheet.surgeon"
                         v-validate="'required'"
                         name="sheet.surgeon"
                         :class="{'form-control': true, 'is-invalid': errors.has('sheet.surgeon')}">
    </form-control>
    <form-control label="Department">
      <input type="text" v-model="sheet.department"
                         v-validate="'required'"
                         name="sheet.department"
                         :class="{'form-control': true, 'is-invalid': errors.has('sheet.department')}">
    </form-control>
    <form-control label="Operating Room">
      <input type="text" v-model="sheet.ot"
                         v-validate="'required'"
                         name="sheet.ot"
                         :class="{'form-control': true, 'is-invalid': errors.has('sheet.ot')}">
    </form-control>

    <form-control label="Time of Antibiotic Administration">
      <datetimepicker :value.sync='sheet.antibiotic_time'></datetimepicker>
    </form-control>

    <form-control label="Antibiotic Second Dose">
      <datetimepicker :value.sync='sheet.repeat_antibiotic_time'></datetimepicker>
    </form-control>

    <form-control label="Discharge Date">
      <datepicker v-model='sheet.hospital_discharge'></datepicker>
    </form-control>

    <label>Antibiotics</label>
    <div class="grid">
      <form-list :value="sheet.antibiotics"
         @input='sheet.antibiotics = $event'
         :default="() => new obj_factory.Antibiotic">
        <template slot="row" slot-scope="{ row, index, onchange }">
          <div class="col-md grid-cell">
            <label>Antibiotic</label>
            <select v-bind:name="'antibiotic-' + index"
                    v-model="row.antibiotic"
                    v-validate="'required'"
                    placeholder="Antibiotic"
                    :class="{'form-control': true, 'is-invalid': errors.has('antibiotic-' + index)}">
              <option v-for="choice in antibiotic_list"
                      v-bind:value="choice"
                      :key="choice">
              {{ choice }}
              </option>
            </select>
            <div class="invalid-feedback">
              Required
            </div>
          </div>
          <div class="col-md grid-cell">
            <label>Started</label>
            <datepicker v-model='row.started' @input='onchange'></datepicker>
          </div>
          <div class="col-md grid-cell">
            <label>Stopped</label>
            <datepicker v-model='row.stopped' @input='onchange'></datepicker>
          </div>
          <div class="col-md grid-cell">
            <label>Route</label>
              <select
                class="form-control"
                @change='onchange'
                v-model="row.route">
                <option></option>
                <option value="IV">IV</option>
                <option value="PO">Oral</option>
                <option value="IM">IM</option>
              </select>
          </div>
          <div class="col-md grid-cell form-check">
            <label>Single Dose?</label>
            <div>
              <input
                type="checkbox"
                class='form-check-inline'
                @change='onchange'
                v-model="row.single_dose">
            </div>
          </div>
        </template>
      </form-list>
    </div>

    <div class="grid">
      <label class='grid-heading'>Post-operative Fever</label>
      <div class='col-4 p-0'>
        <form-list :value="sheet.ssi_events.fever"
           @input='sheet.ssi_events.fever = $event'
           :default="() => new obj_factory.PostOperativeTemperature">

          <template slot="row" slot-scope="{ row, index, onchange }">
            <div class="col-md grid-cell">
              <label>Date</label>
              <datepicker v-model='row.date' @input='onchange'></datepicker>
            </div>
            <div class="col-md grid-cell">
              <label>Max Temp (F)</label>
              <fahrenheit v-model='row.max_temp' @input='onchange'/>
            </div>
          </template>
        </form-list>
      </div>
    </div>

    <div class="grid">
      <label class="grid-heading">Surgical Site Observations</label>
      <form-list :value="sheet.ssi_events.surgical_site_observations"
             @input='sheet.ssi_events.surgical_site_observations = $event'
             :default="() => new obj_factory.SurgicalSiteObservation">

        <template slot="row" slot-scope="{ row, index, onchange }">
          <div class="col-md grid-cell">
            <label>Date</label>
            <datepicker v-model='row.date' @input='onchange'></datepicker>
          </div>
          <div class="col-md grid-cell form-check">
            <label>Discharge</label>
            <div>
              <input
                type="checkbox"
                class='form-check-inline'
                @change='onchange'
                v-model="row.discharge">
            </div>
          </div>
          <div class="col-md grid-cell form-check">
            <label>Tenderness</label>
            <div>
              <input
                type="checkbox"
                class='form-check-inline'
                @change='onchange'
                v-model="row.tenderness">
            </div>
          </div>
          <div class="col-md grid-cell form-check">
            <label>Erythema</label>
            <div>
              <input
                type="checkbox"
                class='form-check-inline'
                @change='onchange'
                v-model="row.erythema">
            </div>
          </div>
          <div class="col-md grid-cell">
            <label>Remark</label>
            <input
              type='remark'
              class='form-control'
              step=1
              @change='onchange'
              v-model.number='row.remark'>
          </div>
        </template>
      </form-list>
    </div>

    <div class="grid">
    <label>Cultures</label>
    <form-list v-model="sheet.ssi_events.cultures"  :default="() => new obj_factory.CultureEvent">
        <template slot="row" slot-scope="{row, index, onchange}">
          <div class="col-md grid-cell">
            <label>Date</label>
            <datepicker v-model='row.date' @input='onchange'></datepicker>
          </div>
          <div class="col-md grid-cell">
            <label>Specimen</label>
            <select
              class="form-control"
              @change='onchange'
              v-model="row.specimen">
              <option></option>
              <option v-for="option in specimen_list"
                      :key="option"
                      v-bind:value="option">{{ option | titlecase }}</option>
            </select>
          </div>
          <div class="col-md grid-cell">
            <label>Site</label>
            <input
              type='text'
              class='form-control'
              @change='onchange'
              v-model='row.site'>
          </div>

          <div class="col-md grid-cell">
            <label>Organism</label>
            <organism-select
              v-model='row.organism'
              @input='onchange'>
            </organism-select>
          </div>
          <div class="col-md grid-cell">
            <label>Remarks</label>
            <input
              type='text'
              class='form-control'
              @change='onchange'
              v-model='row.remarks'>
          </div>
        </template>
      </form-list>
    </div>
    <button class='btn btn-primary'
            @click.prevent='validateAndSave()'>Save</button>
  </form>
</template>

<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { mapGetters, mapState } from 'vuex'
// import router from '@/router'
import CreateRouterMixin from '@/common/routerMixin'
import _ from 'lodash'
import { differenceInMinutes, parseISO } from 'date-fns'
import FormControlWrapper from '@/components/shared/form_group'
import Datepicker from '@/components/shared/datepicker'
import DateTimePicker from '@/components/shared/datetimepicker'
import FormList from '@/components/shared/FormList'
import Fahrenheit from '@/components/shared/fahrenheit.vue'
import OrganismSelect from '@/components/shared/custom_widgets/organismSelect.vue'

var loadData = route => {
  if (route.matched.some(record => record.meta.edit)) {
    return axios.get(`ic_documents/hospital/${route.params.hospital}/surgical_site_monitoring/${route.params.documentId}/`, axiosConfig)
  } else {
    return axios.get(`patients/${route.params.patientId}/`, axiosConfig)
  }
}

var routerMixin = CreateRouterMixin(loadData)

var SPECIMEN_LIST = ['blood', 'urine', 'csf', 'sputum', 'tracheal_aspirate', 'bronchoalveolar_lavage', 'pus', 'tissue', 'stool', 'other']

function Antibiotic () {
  this.antibiotic = null
  this.started = null
  this.stopped = null
  this.route = null
  this.single_dose = false
}

function PostOperativeTemperature () {
  this.date = null
  this.max_temp = null
}

function SurgicalSiteObservation () {
  this.date = null
  this.discharge = false
  this.tenderness = false
  this.erythema = false
  this.remark = null
}

function CultureEvent () {
  this.date = null
  this.specimen = null
  this.site = null
  this.organism = {
    code: null,
    name: null
  }
  this.remark = null
}

export default {
  name: 'SSSheet',
  mixins: [routerMixin],
  data () {
    return {
      patient: { name: null, hospital_id: null },
      sheet: {
        clinical_details: null,
        surgery: null,
        department: null,
        surgeon: null,
        ot: null,
        surgery_class: null,
        start: null,
        end: null,
        antibiotic_time: null,
        repeat_antibiotic_time: null,
        antibiotics: [],
        ssi_events: {
          fever: [],
          surgical_site_observations: [],
          cultures: []
        },
        metadata: {
          hmis_otvmid: null,
          status: 'ADDED'
        },
        hospital_discharge: null
      },
      specimen_list: SPECIMEN_LIST,
      obj_factory: {
        Antibiotic,
        PostOperativeTemperature,
        SurgicalSiteObservation,
        CultureEvent
      }
    }
  },
  computed: {
    ...mapState(['antibiotic_list']),
    ...mapGetters(['currentHospital']),
    config () {
      return this.$store.state.issue_tracker_config
    },
    surgery_duration () {
      const minutes = differenceInMinutes(parseISO(this.sheet.end), parseISO(this.sheet.start))
      if (minutes >= 1) {
        return {
          hours: Math.floor(minutes / 60),
          minutes: minutes % 60
        }
      }
      return null
    }
  },
  methods: {
    setData (data) {
      if (this.$route.meta.edit) {
        this.sheet = Object.assign(this.sheet, data.content)
        this.patient = Object.assign(this.patient, data.patient)
      } else {
        this.patient = Object.assign(this.patient, _.pick(data, ['id', 'name', 'hospital_id']))
      }
    },
    validateAndSave () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.saveSheet()
        }
      })
    },
    async saveSheet () {
      const data = {
        user: this.$store.state.user.id,
        document_type: 'surgical_site_monitoring',
        content: this.sheet,
        patient: this.$route.params.patientId,
        hospital: this.currentHospital.id
      }
      const formConfig = {
        put: {
          url: `ic_documents/hospital/${this.$route.params.hospital}/surgical_site_monitoring/${this.$route.params.documentId}/`,
          method: 'put'
        },
        post: {
          url: `ic_documents/hospital/${this.$route.params.hospital}/surgical_site_monitoring/`,
          method: 'post'
        }
      }
      const pick = this.$route.meta.edit ? formConfig.put : formConfig.post
      await axios[pick.method](pick.url, data, axiosConfig)
      this.$store.dispatch('display_notification', {
        message: 'Successfully Saved'
      })
    },
    async changeStatus () {
      await axios.post(
        `ic_documents/hospital/${this.$route.params.hospital}/surgical_site_monitoring/change_status/`,
        { items: [this.$route.params.documentId], status: 'added' },
        axiosConfig
      )
      this.$store.dispatch('display_notification', {
        message: 'Successfully Imported'
      })
      this.sheet.metadata.status = 'ADDED'
    }
  },
  components: {
    'form-control': FormControlWrapper,
    datetimepicker: DateTimePicker,
    datepicker: Datepicker,
    'form-list': FormList,
    fahrenheit: Fahrenheit,
    'organism-select': OrganismSelect
  }
}
</script>
<style scoped>
@import '../shared/grid.css'
</style>
