<template>
  <dl>
    <template v-for="item in list">
      <slot :name="dotToSnake(item.value)" :item="expandDot(listData, item.value)">
      <slot :name="dotToSnake(item.value, 'dt')"><dt>{{ item.label }}</dt></slot>
      <slot :name="dotToSnake(item.value, 'dd')" :item='expandDot(listData, item.value)'>
      <dd v-if="item.type === 'datetime'">{{ expandDot(listData, item.value) | datetime }}</dd>
      <dd v-else>{{ expandDot(listData, item.value) }}</dd>
      </slot>
      </slot>
    </template>
  </dl>
</template>
<script>
export default {
  name: 'DetailRender',
  props: {
    list: Array,
    listData: [Object]
  },
  methods: {
    expandDot (obj, valueString) {
      return valueString.split('.').reduce((a, b) => a[b], obj)
    },
    dotToSnake (valueString, appendage) {
      const s = valueString.replace(/\./g, '__')
      return appendage ? `${s}__${appendage}` : s
    }
  }
}
</script>
<style scoped>
dd:empty:after {
  content: '###';
}
</style>
