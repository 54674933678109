<template>
  <div class="fs-field" :class="outer_class">
    <label>{{ label }}</label>
    <textarea class='form-control'
              :value='value'
              :rows='rows'
              :placeholder="placeholder"
              :class="{'is-invalid': $fsV.$dirty && $fsV.$invalid}"
              @input="update($event.target.value)"></textarea>
  </div>
</template>
<script>
import FSValidationMixin from '../FSValidationMixin'
export default {
  name: 'FSTextArea',
  props: [
    'label',
    'value',
    'rows',
    'placeholder',
    'outer_class'
  ],
  methods: {
    update: function (val) {
      this.$fsV.$touch()
      this.$emit('input', val)
    }
  },
  mixins: [
    FSValidationMixin
  ]
}
</script>
