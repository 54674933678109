<template>
  <div class="form-row">
    <div class="form-group" :class="'col-md-'+ col">
      <label>{{ label }}</label>
      <div>
        <slot></slot>
        <div class="invalid-feedback">{{ invalidFeedback }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    invalidFeedback: String,
    col: {
      type: Number,
      default: 6
    }
  }
}
</script>
