<template>
<div>
  <h4>Covid Patient Tracker</h4>
    <div class="form-group row">
      <div class="col-lg-4">
        <input type="text"
        class="form-control"
        placeholder="Search PRN or Name"
        v-model="search_term">
      </div>
    </div>
    <grid-table
      :table-data='covid_monitoring'
      :options='covid_monitoring_grid.options'
      :columns="covid_monitoring_grid.columns">
      <router-link
        slot='id'
        slot-scope='props'
        :to="{name: 'CovidChartDetail', params:  {patientId: props.row.patient.id, documentId: props.row.id, hospital: props.row.hospital }}">
        {{ props.row.id }}
      </router-link>
      <template
        slot='patient'
        slot-scope='props'>
        <router-link
          :to="{name: 'PatientDetail', params:  {hospitalId: props.row.patient.hospital_id, hospital: props.row.hospital }}">{{ props.row.patient.name }}</router-link> #{{ props.row.patient.hospital_id }}
      </template>
      <template
        slot='actions'
        slot-scope='props'>
        <i class='fa fa-trash-o' @click='delete_doc(props.row.id)'></i>
      </template>
      <template slot='no_results'>
        No Sheets Found
      </template>
    </grid-table>
    <grid-pagination
      :page="page"
      :route-link="{ name: $route.name, query: $route.query }"
      :count="result_count">
    </grid-pagination>
</div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { mapGetters } from 'vuex'
import GridTable from '@/components/shared/datatable/Grid'
import GridPagination from '@/components/shared/datatable/GridPagination'
import CreateRouterMixin from '@/common/routerMixin'
import _ from 'lodash'

var getData = function (route) {
  return axios.get(`ic_documents/hospital/${route.params.hospital}/covid_chart/`,
    Object.assign({ params: route.query }, axiosConfig)
  )
}

var routerMixin = CreateRouterMixin(getData)

export default {
  name: 'SurveillancePatientDetail',
  props: {
    page: {
      type: Number,
      default: 1
    }
  },
  mixins: [routerMixin],
  data: () => {
    return {
      covid_monitoring: [],
      result_count: 0,
      covid_monitoring_grid: {
        columns: ['id', 'patient', 'created', 'modified'],
        options: {
          dateColumns: ['created', 'modified'],
          headings: {
            id: '#'
          }
        }
      },
      search_term: ''
    }
  },
  computed: {
    ...mapGetters(['currentHospital']),
    selectedItems () {
      return _.compact(_.map(this.covid_monitoring, item => {
        return item.selected ? item.id : null
      }))
    },
    status () { return this.$route.query.status }
  },
  watch: {
    search_term: function (newSearch, oldSearch) {
      var route = { name: this.$route.name, query: {} }

      // A number usually means hospital ID
      if (newSearch.match(/\d{4,10}/)) { route.query.patient__hospital_id = newSearch }

      // search syntax
      if (newSearch.match(/[A-Za-z]+/)) {
        route.query.search = newSearch
      }

      this.$router.push(route)
    }
  },
  methods: {
    setData (data) {
      this.covid_monitoring = _.map(data.results, result => {
        result.selected = false
        return result
      })
      this.result_count = data.count
    },
    async delete_doc (id) {
      const msg = 'Deleting document...'
      if (confirm(msg)) {
        await axios.delete(`ic_documents/hospital/${this.$route.params.hospital}/covid_chart/${id}/`, axiosConfig)
        this.$store.dispatch('display_notification', {
          message: 'Successfully Deleted'
        })
        const response = await getData(this.$router.currentRoute)
        this.setData(response.data)
      }
    }
  },
  components: {
    'grid-table': GridTable,
    'grid-pagination': GridPagination
  }
}
</script>
