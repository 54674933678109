<template>
  <app-frame>
    <template v-slot:sidebar>
      <li>
        <router-link class="nav-link"
          active-class="active"
          :to="{ name: 'CovidChartList' }"
          exact>
          <i class="fa fa-fw fa-trash"></i>List</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" :to="{ name: 'CovidChartStart', params: { hospital: currentHospital.code }, query: { nextView: 'CovidPatientDetail' }}">
          <i class="fa fa-fw fa-plus"></i>New Sheet</router-link>
      </li>
    </template>
    <template slot="main">
      <p><span class="hospital_name"><i class="fa fa-h-square"> </i> {{ currentHospital.name }}</span> <router-link class="small" :to="{ name: 'HospitalSelector' }">[change]</router-link></p>
      <router-view></router-view>
    </template>
  </app-frame>
</template>
<script>
import AppFrame from '@/components/AppFrame'
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'CovidChartFrame',
  components: {
    'app-frame': AppFrame
  },
  computed: {
    ...mapGetters(['currentHospital']),
    config () {
      return this.$store.state.issue_tracker_config
    }
  },
  mounted () {
    axios.get(`issue-tracker/hospital/${this.currentHospital.code}/config`, axiosConfig).then(response => {
      this.$store.commit('populate_issue_tracker_config', response.data)
    })
  }
}
</script>
<style scoped>
.hospital_name {
    text-transform: uppercase;
    font-weight: bold;
    display: inline-block;
    padding: 2px 5px;
    color: #d81b60;
}

</style>
