<template>
  <div>
    <h4>Infection Control Findings</h4>
    <grid-table
      :columns='columns'
      :options="table_options"
      :table-data="table_data">
      <span slot='location' slot-scope="props">{{ friendly_location(props.row.location) }}</span>
      <router-link slot='id' slot-scope='props' :to="{name: 'TicketDetail', params: {ticketId: props.row.id, hospital: $route.params.hospital}}" class="bold">{{ props.row.id }}</router-link>
      <span slot='category' slot-scope="props">{{ props.row.category | human_format }}</span>
      <span slot='text' slot-scope="props">{{ props.row.text | truncate }}</span>
    </grid-table>
    <grid-pagination
      :page="page"
      :route-link="{ name: $route.name, query: $route.query }"
      :count="result_count">
    </grid-pagination>
  </div>
</template>

<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import humanFormat from '@/components/issue_tracker/filters.js'
import _ from 'lodash'
import CreateRouterMixin from '@/common/routerMixin'
import GridTable from '@/components/shared/datatable/Grid'
import GridPagination from '@/components/shared/datatable/GridPagination'
// import _ from 'lodash'

var getData = function (route) {
  return axios.get(`issue-tracker/hospital/${route.params.hospital}/tickets/`,
    Object.assign({ params: route.query }, axiosConfig))
}

var routerMixin = CreateRouterMixin(getData)

export default {
  name: 'IssueTrackerList',
  props: {
    page: {
      type: Number,
      default: 1
    }
  },
  components: {
    'grid-table': GridTable,
    'grid-pagination': GridPagination
  },
  mixins: [routerMixin],
  data: function () {
    return {
      columns: ['id', 'datetime', 'location', 'category', 'text'],
      table_data: [],
      result_count: 0,
      table_options: {
        dateColumns: ['datetime'],
        headings: {
          id: '#'
        }
      }
    }
  },
  computed: {
    config () {
      return this.$store.state.issue_tracker_config
    },
    location_mapping () {
      var locationChoices = this.config.location_choices
      var reversedMap = {}
      locationChoices.forEach(function (floor) {
        floor.locations.forEach(function (locationDetail) {
          reversedMap[locationDetail.location] = [floor.label, locationDetail.label]
        })
      })
      return reversedMap
    }
  },
  filters: {
    human_format: humanFormat,
    truncate: function (text) {
      return _.truncate(text, {
        length: 50,
        separator: ' '
      })
    }
  },
  methods: {
    setData (data) {
      this.table_data = data.results
      this.result_count = data.count
    },
    friendly_location (value) {
      try {
        return this.location_mapping[value].join(' / ')
      } catch (error) {
        return value
      }
    }
  }
}
</script>
<style scoped>
  th {
    text-transform: uppercase;
  }
</style>
