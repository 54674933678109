import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import _ from 'lodash'

Vue.use(Vuex)

const INITIAL_USER_STATE = {
  email: null,
  id: null,
  name: null,
  permissions: {
    is_superuser: false,
    is_staff: false,
    is_whitelisted_ip: false,
    inferred_location: null,
    all_permissions: []
  }
}

const INITIAL_NOTIFICATION_STATE = {
  message: '',
  type: 'info',
  duration: 6000
}

export default new Vuex.Store({
  state: {
    user: INITIAL_USER_STATE,
    hospitals_config: [],
    loading_animation: false,
    request_table: {
      page: 1
    },
    antibiotic_authorization_config: {
      antibiotics: ['meropenem', 'imipenem', 'doripenem', 'ertapenem',
        'ceftazidime_avibactam', 'aztreonam',
        'colistin', 'polymyxin_b', 'tigecycline',
        'vancomycin', 'teicoplanin', 'linezolid',
        'amphotericin_b', 'caspofungin', 'anidulafungin', 'micafungin', 'voriconazole']
    },
    ventilationModes: [
      { label: 'Spontaneous - Room Air', value: 'spontaneous_ambient_air' },
      { label: 'Spontaneous - Nasal Prongs', value: 'spontaneous_prongs' },
      { label: 'Spontaneous - High Flow Oxygen / Non Rebreathing Mask', value: 'spontaneous_high_flow_oxygen' },
      { label: 'Non Invasive Ventilation', value: 'non_invasive_ventilation' },
      { label: 'Invasive Ventilation - CPAP/PSV', value: 'invasive_ventilation_cpap_psv' },
      { label: 'Invasive Ventilation - SIMV', value: 'invasive_ventilation_simv' },
      { label: 'Invasive Ventilation - CMV', value: 'invasive_ventilation_cmv' }
    ],
    antibiotic_list: [],
    clinical_departments: [],
    issue_tracker_config: {
      category_choices: [],
      location_choices: [],
      department_choices: []
    },
    notification: INITIAL_NOTIFICATION_STATE
  },
  getters: {
    getHospitalByCode: (state) => (hospitalCode) => {
      return state.hospitals_config.find(hospital => hospital.code === hospitalCode)
    },
    currentHospital: (state, getters) => getters.getHospitalByCode(state.route.params.hospital),
    hospitals_config: state => state.hospitals_config,
    antibiotic_choices: (state) => {
      const antibiotics = state.antibiotic_authorization_config.antibiotics
      return _.map(antibiotics, (val, index) => {
        return {
          value: val,
          text: _.startCase(_.replace(val, '_', ' ')) // Capitalize each word
        }
      })
    },
    hmis_base_url: (state) => {
      if (state.user.permissions.is_whitelisted_ip) {
        return 'http://192.168.10.24'
      }
      return 'http://hmis.sahyadrihospitals.com'
    }
  },
  mutations: {
    set_user (state, user) {
      state.user = user
    },
    logout_user (state) {
    },
    populate_issue_tracker_config (state, config) {
      Object.assign(state.issue_tracker_config, config)
      // state.issue_tracker_config.populated = true
    },
    load_hospital_config (state, config) {
      state.hospitals_config = config.hospitals
      state.antibiotic_list = config.antibiotic_list
      state.clinical_departments = config.clinical_departments
    },
    set_loading_animation (state, show) {
      state.loading_animation = show
    },
    set_notification (state, message) {
      state.notification = message
    }
  },
  actions: {
    load_application_config ({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (state.hospitals_config.length === 0) {
          return axios.get('config/application_config', axiosConfig).then(response => {
            commit('load_hospital_config', response.data)
            resolve()
          }).catch(response => {
            reject()
          })
        } else {
          resolve()
        }
      })
    },
    load_credentials ({ commit, state }) {
      return new Promise((resolve, reject) => {
        return axios.get('auth/credentials', axiosConfig).then(response => {
          commit('set_user', response.data)
          resolve()
        }).catch(response => {
          reject()
        })
      })
    },
    login_user ({ dispatch, commit }, credentials) {
      return axios.post('auth/login', {
        email: credentials.email,
        password: credentials.password
      }, axiosConfig).then(() => {
        dispatch('load_credentials')
      })
    },
    logout_user ({ commit, state }) {
      return axios.post('auth/logout', {}, axiosConfig).then(response => {
        state.user = INITIAL_USER_STATE
      })
    },
    display_notification ({ commit, state }, message) {
      var notification = {
        message: message.message,
        duration: message.duration || INITIAL_NOTIFICATION_STATE.duration,
        type: message.type || INITIAL_NOTIFICATION_STATE.type
      }
      commit('set_notification', notification)
      setTimeout(() => {
        commit('set_notification', INITIAL_NOTIFICATION_STATE)
      }, notification.duration)
    }
  }
})
