<template>
  <div style="position:relative">
    <input
    class="form-control"
    type="text"
    v-bind:placeholder="placeholder ? placeholder : 'Type for suggestions'"
    v-model="input_text"
    v-on:blur="blur($event)"
    v-on:focus="focus"
    @input="updateValue($event.target.value)"
    @keydown.enter.prevent='enter'
    @keydown.up='up'
    @keydown.down='down'/>
    <div class="autocomplete-dropdown"
      :class="{'open': openSuggestion}"
      style="width:100%">
      <div v-for="(suggestion, index) in options"
          v-bind:class="{'active': isActive(index)}"
          class="autocomplete-dropdown-item"
          href="#"
          v-on:mousedown="setIgnoreBlur(true)"
          v-on:mouseup="setIgnoreBlur(false)"
          @click="suggestionClick(index)">
        {{ getSuggestionLabel(suggestion) }}
      </div>
      <div v-if="options.length === 0" class="autocomplete-dropdown-item">
        <slot name="no_search_results">
        (No search results)
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Autocomplete',
  props: {
    value: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    noForceFromSuggestions: Boolean,
    getSuggestionLabel: {
      type: Function,
      default: suggestion => suggestion
    },
    getInputText: {
      type: Function,
      default: i => i
    },
    placeholder: String
  },
  data () {
    return {
      open: false,
      current: 0,
      input_text: this.getInputText(this.value),
      ignore_blur: false
    }
  },
  methods: {
    updateValue (value) {
      this.input_text = value

      if (this.open === false) {
        this.open = true
        this.current = 0
      }
      this.$emit('search-change', value)

      if (this.noForceFromSuggestions) {
        this.$emit('update:value', this.input_text)
      }
      if (value === '') {
        this.$emit('input-cleared')
      }
    },
    enter () {
      this.$emit('update:value', this.options[this.current])
      this.input_text = this.getInputText(this.options[this.current])
      this.open = false
    },
    up () {
      if (this.current > 0) {
        this.current--
      }
    },
    down () {
      if (this.current < this.options.length - 1) {
        this.current++
      }
    },
    isActive (index) {
      return index === this.current
    },
    suggestionClick (index) {
      this.input_text = this.getInputText(this.options[index])
      this.open = false
      // this.ignore_blur = false
      this.$emit('update:value', this.options[index])
    },
    focus () {
      this.$emit('focus')
      this.input_text = this.getInputText(this.value)
    },
    blur () {
      if (this.ignore_blur) {
        return
      }
      if (!this.noForceFromSuggestions) {
        this.input_text = this.getInputText(this.value)
        this.$emit('update:value', this.value)
      }
      this.open = false
      this.$emit('blur')
    },
    setIgnoreBlur (ignore) {
      this.ignore_blur = ignore
    }
  },
  computed: {
    openSuggestion () {
      return this.open
      // return this.options.length !== 0 && this.open === true
    }
  },
  watch: {
    options: function () {
      this.current = 0
    },
    value: function () {
      this.input_text = this.getInputText(this.value)
    }
  }
}
</script>
<style scoped>
.autocomplete-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: 0;
    margin: 0;
    font-size: 14px;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.25rem;
}
.autocomplete-dropdown.open {
    display: block;
}

.autocomplete-dropdown-item {
    display: block;
    width: 100%;
    padding: 0.25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background: none;
    border: 0;
    cursor: pointer;
    overflow-x: hidden;
    font-size: 12px;
    padding-left: 10px;
}
.autocomplete-dropdown-item.active {
  background-color: blue;
  color: white;
}

.autocomplete-dropdown-item:hover {
  background-color: blue;
  color: white;
}
.is-invalid .form-control {
  border: 1px solid red;
}
.is-invalid .form-control:focus {
  border: 1px solid red;
  box-shadow: 0 0 0 1px red;
}
</style>
