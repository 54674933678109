<template>
<div>
    <p class="mb-4"><strong>Patient Details</strong></p>
    <form v-on:submit.prevent>
        <form-control label="PRN">
                <input type="text" name="PRN" class="form-control" v-model="hospital_id"/>
        </form-control>
        <form-control label="Name">
                <input type="text" class="form-control" v-model="name"/>
        </form-control>
        <button class="btn btn-primary" v-on:click.stop="save_patient">Save</button>
    </form>
</div>
</template>

<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import router from '@/router'
import _ from 'lodash'
import FormControlWrapper from '@/components/shared/form_group'

export default {
  name: 'PatientEdit',
  data () {
    return {
      hospital_id: null,
      name: null
    }
  },
  mounted () {
    this.hospital_id = this.$route.query.hospital_id

    axios.get(`hmis_functions/admissions/?hospital_id=${this.hospital_id}`, axiosConfig).then(response => {
      if (response.data.count > 0) {
        this.name = response.data.results[0].patient_name
      }
    })
  },
  methods: {
    correct_name: function (name) {
      name = name.replace(/^(Mrs?|Ms|Dr)\.?\s?/, '') // Users have an annoying habit of writing prefix salutations!
      return _.startCase(_.toLower(name)) // Also correct the capitalization
    },
    save_patient: function () {
      axios.post('patients/', {
        hospital_id: this.hospital_id,
        name: this.correct_name(this.name)
      }, axiosConfig).then(response => {
        router.push({ path: this.$route.query.onSuccess })
      })
    }
  },
  components: {
    'form-control': FormControlWrapper
  }
}
</script>
