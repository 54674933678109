<template>
  <div class="fs-field" :class="outer_class">
    <label>{{ label }}</label>
    <select class='form-control'
            v-model='internalVal'
            :class="{'is-invalid': $fsV.$dirty && $fsV.$invalid}"
            @blur="$fsV.$touch()">
      <option disabled value="">{{ placeholder }}</option>
      <template v-if="optgroups">
        <optgroup v-for="optgroup in options" :label="optgroup.label">
          <option v-for="option in optgroup.items"
                  v-bind:value="option.location">{{ option.label }}</option>
        </optgroup>
      </template>
      <template v-else>
        <option
          v-for="option in options"
          :key="option.value"
          :value="option.value">
        {{ option.label }}
        </option>
      </template>
    </select>
  </div>
</template>
<script>
import FSValidationMixin from '../FSValidationMixin'
export default {
  name: 'FSSelectInput',
  props: {
    label: String,
    value: null,
    placeholder: String,
    outer_class: String,
    options: null,
    optgroups: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalVal: {
      get () {
        return this.value
      },
      set (val) {
        this.$fsV.$touch()
        this.$emit('input', val)
      }
    }
  },
  mixins: [
    FSValidationMixin
  ]
}
</script>
