<template>
  <div>
    <div class="notification" :class="[notificationClass.type, {active: notificationClass.active, hidden: !notificationClass.active}]">{{ notification.message }}</div>
  </div>
</template>

<script>
// import router from '@/router'
import { mapState } from 'vuex'
export default {
  name: 'NotificationBar',
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
    notificationClass () {
      return {
        type: `bg-${this.notification.type}`,
        active: this.notification.message !== ''
      }
    },
    ...mapState(['notification'])
  }
}
</script>
<style scoped>
@media (max-width: 767px) {
  .main-content-wrapper {
    padding-right: 0;
    padding-left: 0;
  }
}

.frame-link.active {
  /* display: none!important; */
}

.notification {
  position: fixed;
  bottom: 0px;
  left: 0px;
  color: #FFF;
  padding: 10px 5px;
  width: 100%;
  font-size: 16px;
  text-align: center;
  bottom: -30px;
  z-index: 1000;
}

.notification.active {
  animation: fadein 0.5s;
  bottom: 0px;
}

.notification.hidden {
  animation: fadeout 0.5s;
  opacity: 0;
  bottom: -30px;
}

.notification.bg-error {
  background-color: red;
}

@keyframes fadein {
  from {bottom: -30px; opacity: 0;}
  to {bottom: 0px; opacity: 1;}
}

@keyframes fadeout {
  from {bottom: 0px; opacity: 1;}
  to {bottom: -30px; opacity: 0;}
}
</style>
