<template>
  <div>
    <form novalidate @submit.prevent="validateAndSave()">
      <div>
        <p>{{ patient.hospital_id }} / <b>{{ patient.name }}</b> / <span v-for="(antibiotic, index) in antibiotics" :key="index">{{ antibiotic.antibiotic }}<span v-if="index+1 < antibiotics.length">, </span></span></p>
      </div>
      <div class="form-group row">
        <label for="ast_status" class="col-form-label col-md-2">Processing status</label>
        <div class="col-md-4">
          <select class="form-control"
                  name="ast_status"
                  v-model="ast_status"
                  v-validate="'required'"
                  :class="{'is-invalid':errors.has('ast_status')}">
            <option></option>
            <option value="Approved">Approved</option>
            <option value="Rejected">Rejected</option>
            <option value="Pending">Pending</option>
            <option value="Disagreement">Disagreement</option>
            <option value="Provisional Approval">Provisional Approval</option>
            <option value="Non-Compliance">Non-Compliance</option>
          </select>
          <div class="invalid-feedback">
            Required
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="ast_review" class="col-form-label col-md-2">HIC Review</label>
        <div class="col-md-10">
          <textarea v-model="ast_review"
                    name="ast_review"
                    v-validate="'required'"
                    :class="{'is-invalid': errors.has('ast_review')}"
                    rows=8 class="form-control"></textarea>
          <div class="invalid-feedback">
            Required
          </div>
          <div class="mt-3">
            <button v-on:click="addTodayDateString()" type="button" class="btn-hashtag btn btn-outline-primary btn-sm m-1">+ Date</button>
            <button v-for="(hashtag, index) in hashtags"
              :key="hashtag"
              v-on:click="addHashTag(hashtag)"
              type="button" class="btn btn-outline-primary btn-sm btn-hashtag m-1">{{ hashtag }}</button>
          </div>
        </div>
      </div>
      <button class="btn btn-primary"
              >Save</button>
    </form>
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { format } from 'date-fns'

export default {
  name: 'RequestProcess',
  props: ['params'],
  data () {
    return {
      patient: {
        name: null,
        id: null,
        hospital_id: null
      },
      antibiotics: [],
      ast_status: null,
      ast_review: null,
      hashtags: [
        'Empirical',
        'Culture Appropriate',
        'Cultures Awaited',
        'Colonisation',
        'De-escalated',
        'Not De-escalated',
        'Incorrect Doses',
        'Incorrect Choice',
        'Inappropriate Escalation',
        'Rejected',
        'Narrow spectrum alternatives available',
        'Inappropriate Surgical Prophylaxis',
        'Non-Compliance'
      ]
    }
  },
  computed: {
    ...mapGetters(['currentHospital'])
  },
  methods: {
    setInitialData (data) {
      _.extend(this, _.pick(data, ['patient', 'antibiotics', 'ast_status', 'ast_review']))
    },
    addHashTag (hashtag) {
      if (this.ast_review === null) {
        this.ast_review = ''
      }
      this.ast_review = this.ast_review + ' #' + _.camelCase(hashtag)
    },
    addTodayDateString () {
      const todayString = format(new Date(), 'yyyy-MM-dd' + ': ')
      this.ast_review = this.ast_review === null
        ? todayString
        : this.ast_review + '\n\n' + todayString
    },
    validateAndSave () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.save()
        }
      })
    },
    save () {
      var data = {
        ast_review: this.ast_review,
        ast_status: this.ast_status
      }
      var requestId = this.params.requestId
      axios.put(`hospital/${this.currentHospital.code}/antibiotic_authorization/${requestId}/ast_process/`, data, axiosConfig)
        .then(response => {
          this.$emit('process-finished')
        }).catch(error => {
          console.log(error)
        })
    }
  },
  watch: {
    params: function (newVal, oldVal) {
      getData(newVal).then(response => {
        this.errors.clear()
        this.setInitialData(response.data)
      })
    }
  }
}

var getData = (params) => {
  return axios.get(`hospital/${params.hospital}/antibiotic_authorization/${params.requestId}/`, axiosConfig)
}
</script>
<style scoped>
label {
  font-weight: 700;
}
.btn.btn-sm.btn-hashtag {
  padding: 0px 4px;
  font-size: 90%;
}
</style>
