<template>
  <div>
    <h4 class="mb-3">
     Ticket Details</h4>
    <dl>
      <dt>Date</dt>
      <dd>{{ datetime | datetime }}</dd>
      <dt>Location</dt>
      <dd>{{ location }}</dd>
      <dt>Department</dt>
      <dd>{{ department }}</dd>
      <dt>Category</dt>
      <dd>{{ category }}</dd>
      <dt>Severity</dt>
      <dd>{{ severity }}</dd>
      <dt>Text</dt>
      <dd>{{ text }}</dd>
      <dt>Employee</dt>
      <dd v-if="related_person">{{ related_person }}</dd>
      <dd v-else>[Not Recorded]</dd>
      <dt>Corrective Action</dt>
      <dd v-if="corrective_action">{{ corrective_action }}</dd>
      <dd v-else>[Not Recorded]</dd>
    </dl>
    <div class="mt-5">
      <router-link :to="{name:'TicketEdit', params: { ticketId: id }}" class="btn btn-link">Edit</router-link>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import CreateRouterMixin from '@/common/routerMixin'

var getData = function (route) {
  return axios.get(`issue-tracker/hospital/${route.params.hospital}/tickets/${route.params.ticketId}/`, axiosConfig)
}

var routerMixin = CreateRouterMixin(getData)

export default {
  name: 'TicketDetail',
  mixins: [routerMixin],
  data: () => {
    return {
      id: null,
      datetime: null,
      location: null,
      category: null,
      department: null,
      text: null,
      severity: null,
      related_person: null,
      corrective_action: null
    }
  },
  methods: {
    setData (data) {
      Object.assign(this, data)
    }
  }
}

</script>
<style scoped>
</style>
