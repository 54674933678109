<template>
<div>
  <ul v-if="paginationEnabled" class="pagination">
    <li v-if="hasPrevious" class="page-item"><router-link class="page-link" :to="getRouteLink(page-1)">&lt;</router-link></li>
    <li class="page-item active"><router-link class="page-link" :to="getRouteLink(page)">{{page}}</router-link></li>
    <li v-if="hasNext" class="page-item"><router-link class="page-link" :to="getRouteLink(page+1)">&gt;</router-link></li>
  </ul>
</div>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'GridPagination',
  props: {
    page: {
      type: Number,
      default: 1
    },
    count: {
      type: Number
    },
    perPage: {
      type: Number,
      default: 10
    },
    routeLink: {
      type: Object
    }
  },
  computed: {
    totalPages () {
      return Math.ceil(this.count / this.perPage)
    },
    hasPrevious () {
      return this.totalPages > 1 && this.page > 1
    },
    hasNext () {
      return this.page < this.totalPages
    },
    paginationEnabled () {
      return this.count > this.perPage
    }
  },
  methods: {
    getRouteLink (pageNum) {
      var link = _.cloneDeep(this.routeLink)
      link.query.page = pageNum
      return link
    }
  }
}
</script>
