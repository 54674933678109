<template>
  <div>
    <h4 class="mb-3">
      Surgery Detail</h4>

    <div v-if='formData.sheet.metadata.status === "PENDING"' class="alert alert-warning" role="alert">
      Warning: This auto-imported form is <b>NOT YET APPROVED</b>
    </div>

    <detail-render :list="list_options" :list-data="formData" >

      <dd slot='sheet__antibiotics__dd' slot-scope='props'>
      <span
        v-for="(antibiotic, index) in props.item"
        :key="index">{{ antibiotic.route }} {{ antibiotic.antibiotic }} - started {{ antibiotic.started | datetime }}, stopped {{ antibiotic.stopped | datetime }} <template v-if="antibiotic.single_dose">(single dose)</template><br></span>
      </dd>

      <dd slot="sheet__ssi_events__fever__dd" slot-scope="props">
      <span
        v-for="(observation, index) in props.item">
        {{ observation.date | datetime }} - {{ observation.max_temp | fahrenheit }} F<br>
      </span>
      </dd>

      <dd slot="sheet__ssi_events__surgical_site_observations__dd">
      <span
        v-for="(observation, index) in formData.sheet.ssi_events.surgical_site_observations">
        {{ observation.date | datetime }} -
        <span v-for="observation_type in ['tenderness', 'erythema', 'discharge']">
         <i class="fa" :class="observation[observation_type] ? 'fa-check' : 'fa-times'"> </i> {{ observation_type }}
        </span>
        <span v-if="observation.remark">({{ observation.remark }})</span>
      </span>
      </dd>
    </detail-render>
    <div class="mt-5">
      <router-link :to="{name:'SurgeryMonitoringFormEdit', params: { patientId: formData.patient.id, hospital: $route.params.hospital, documentId: $route.params.documentId}}" class="btn btn-link">Edit</router-link>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
// import { differenceInMinutes, parseISO } from 'date-fns'
import { mapGetters } from 'vuex'
import CreateRouterMixin from '@/common/routerMixin'
import detailRender from '@/components/shared/custom_widgets/detailRender'

const loadData = route => {
  return axios.get(`ic_documents/hospital/${route.params.hospital}/surgical_site_monitoring/${route.params.documentId}/`, axiosConfig)
}

const routerMixin = CreateRouterMixin(loadData)

export default {
  name: 'SSDetail',
  mixins: [routerMixin],
  data () {
    return {
      formData: {
        patient: { name: null, hospital_id: null },
        sheet: {
          clinical_details: null,
          surgery: null,
          department: null,
          surgeon: null,
          ot: null,
          surgery_class: null,
          start: null,
          end: null,
          antibiotic_time: null,
          antibiotics: [],
          ssi_events: {
            fever: [],
            surgical_site_observations: [],
            cultures: []
          },
          metadata: {
            hmis_otvmid: null,
            status: 'ADDED'
          },
          hospital_discharge: null
        }
      },
      list_options: [
        { label: 'Name', value: 'patient.name' },
        { label: 'PRN', value: 'patient.hospital_id' },
        { label: 'Clinical Details', value: 'sheet.clinical_details' },
        { label: 'Surgery', value: 'sheet.surgery' },
        { label: 'Surgeon', value: 'sheet.surgeon' },
        { label: 'Department', value: 'sheet.department' },
        { label: 'Surgery Category', value: 'sheet.surgery_class' },
        { label: 'Operating Room', value: 'sheet.ot' },
        { label: 'Surgery Start', value: 'sheet.start', type: 'datetime' },
        { label: 'Surgery End', value: 'sheet.end', type: 'datetime' },
        { label: 'Prophylaxis Time', value: 'sheet.antibiotic_time', type: 'datetime' },
        { label: 'Antibiotics', value: 'sheet.antibiotics' },
        { label: 'Post-operative Fever', value: 'sheet.ssi_events.fever' },
        { label: 'Surgical Site Observations', value: 'sheet.ssi_events.surgical_site_observations' },
        { label: 'Hospital Discharge', value: 'sheet.hospital_discharge', type: 'datetime' }
      ]
    }
  },
  computed: {
    ...mapGetters(['currentHospital'])
  },
  methods: {
    setData (data) {
      this.formData.sheet = Object.assign(this.formData.sheet, data.content)
      this.formData.patient = Object.assign(this.formData.patient, data.patient)
    }
  },
  components: {
    'detail-render': detailRender
  }
}
</script>
