import Vue from 'vue'
import Router from 'vue-router'
import Request from '@/components/a_authorization/Request'
import RequestList from '@/components/a_authorization/RequestList'
import RequestNew from '@/components/a_authorization/RequestNew'
import RequestStart from '@/components/a_authorization/RequestStart'
import RequestProcess from '@/components/a_authorization/RequestProcess'
import RequestPatientDetail from '@/components/a_authorization/PatientDetail'
import RequestDetail from '@/components/a_authorization/RequestDetail'
import RequestStatisticsIndex from '@/components/a_authorization/RequestStatisticsIndex'
import RequestStatistics from '@/components/a_authorization/RequestStatistics'

import Ticket from '@/components/issue_tracker/Ticket'
import IssueTrackerList from '@/components/issue_tracker/IssueTrackerList'
import TicketDetail from '@/components/issue_tracker/TicketDetail'
import TicketEdit from '@/components/issue_tracker/TicketEdit'

import ICUSurveillance from '@/components/icu_monitoring/ICUSurveillance'
import ICUSurveillanceSheet from '@/components/icu_monitoring/ICUSurveillanceSheet'
import SurveillanceList from '@/components/icu_monitoring/List'
import StartSheet from '@/components/icu_monitoring/StartSheet'
import ICUSurveillanceDataExport from '@/components/icu_monitoring/DataExport'

import SSFrame from '@/components/surgery_surveillance/Frame'
import SurgeryMonitoringForm from '@/components/surgery_surveillance/Form'
import SurgeryMonitoringList from '@/components/surgery_surveillance/List'
import SurgeryMonitoringStart from '@/components/surgery_surveillance/StartSheet'
import SurgeryDetail from '@/components/surgery_surveillance/Detail'
import SurgeryMonitoringDataExport from '@/components/surgery_surveillance/DataExport'

import AppLogin from '@/components/auth/AppLogin'
import HospitalSelector from '@/components/HospitalSelector'
import NotFound from '@/components/shared/NotFound'

import PatientFrame from '@/components/patient/Frame'
import PatientDetail from '@/components/patient/Patient'
import PatientEdit from '@/components/patient/PatientEdit'

import covidChartRoutes from '@/components/covid_charts/routes'
import vaeRoutes from '@/components/vae/routes'

Vue.use(Router)

const baseRoutes = [
  {
    path: '/',
    name: 'MainFrame.vue',
    component: Request,
    redirect: { name: 'HospitalSelector', query: { autodetect: '1' } }
  },
  {
    path: '/app/:hospital/patients',
    component: PatientFrame,
    children: [
      {
        path: 'patient/:hospitalId',
        name: 'PatientDetail',
        component: PatientDetail
      }
    ]
  },
  {
    path: '/app/:hospital/antibiotic_authorization',
    name: 'RequestFrame.vue',
    component: Request,
    children: [
      {
        path: 'requests',
        query: 'page',
        name: 'RequestList',
        component: RequestList,
        props: (route) => (
          {
            page: typeof route.query.page === 'undefined' ? 1 : parseInt(route.query.page)
          }
        )
      },
      {
        path: 'patient/new',
        name: 'PatientEdit',
        component: PatientEdit
      },
      {
        path: 'patient/:hospital_id',
        name: 'RequestPatientDetail',
        component: RequestPatientDetail
      },
      {
        path: 'patient/:patientId/request/new',
        name: 'RequestNew',
        component: RequestNew,
        meta: { edit: false }
      },
      {
        path: 'patient/:patientId/request/:requestId/edit',
        name: 'RequestEdit',
        component: RequestNew,
        meta: { edit: true }
      },
      {
        path: 'patient/:patientId/request/:requestId/process',
        name: 'RequestProcess',
        component: RequestProcess
      },
      {
        path: 'requests/start',
        name: 'RequestNewStart',
        component: RequestStart
      },
      {
        path: 'requests/:requestId',
        name: 'RequestDetail',
        component: RequestDetail
      },
      {
        path: 'statistics',
        name: 'RequestStatisticsIndex',
        component: RequestStatisticsIndex
      },
      {
        path: 'statistics/:year/:month',
        name: 'RequestStatistics',
        component: RequestStatistics
      }
    ]
  },
  {
    path: '/app/:hospital/issue_tracker/tickets',
    name: 'IssueTracker',
    component: Ticket,
    children: [
      {
        path: 'list',
        name: 'IssueTrackerList',
        component: IssueTrackerList,
        props: (route) => (
          {
            page: typeof route.query.page === 'undefined' ? 1 : parseInt(route.query.page)
          }
        )
      },
      {
        path: 'new',
        name: 'TicketNew',
        component: TicketEdit,
        meta: { edit: false }
      },
      {
        path: ':ticketId',
        name: 'TicketDetail',
        component: TicketDetail
      },
      {
        path: ':ticketId/edit',
        name: 'TicketEdit',
        component: TicketEdit,
        meta: { edit: true }
      }
    ]
  },
  {
    path: '/app/:hospital/surveillance/device_monitoring',
    name: 'CriticalCareMonitoring',
    component: ICUSurveillance,
    children: [
      {
        path: 'list',
        name: 'SurveillanceList',
        component: SurveillanceList,
        props: (route) => (
          {
            page: typeof route.query.page === 'undefined' ? 1 : parseInt(route.query.page)
          }
        )
      },
      {
        path: 'patient/:hospitalId',
        name: 'SurveillancePatientDetail',
        component: PatientDetail
      },
      {
        path: 'start',
        name: 'StartSheet',
        component: StartSheet
      },
      {
        path: 'export',
        name: 'ICUSurveillanceDataExport',
        component: ICUSurveillanceDataExport
      },
      {
        path: 'patient/:patientId/sheet/:documentId',
        name: 'ICUSurveillanceSheet',
        component: ICUSurveillanceSheet,
        meta: { edit: false }
      }
    ]
  },
  {
    path: '/app/:hospital/surveillance/surgeries',
    name: 'SurgerySurveillance',
    component: SSFrame,
    children: [
      {
        path: 'list',
        name: 'SurgeryMonitoringList',
        component: SurgeryMonitoringList,
        props: (route) => (
          {
            page: typeof route.query.page === 'undefined' ? 1 : parseInt(route.query.page)
          }
        )
      },
      {
        path: 'start',
        name: 'SurgeryMonitoringStart',
        component: SurgeryMonitoringStart
      },
      {
        path: 'patient/:patientId/sheet/new',
        name: 'SurgeryMonitoringFormNew',
        component: SurgeryMonitoringForm,
        meta: { edit: false }
      },
      {
        path: 'export',
        name: 'SurgeryMonitoringDataExport',
        component: SurgeryMonitoringDataExport
      },
      {
        path: 'patient/:patientId/sheet/:documentId',
        name: 'SurgeryMonitoringFormDetail',
        component: SurgeryDetail
      },
      {
        path: 'patient/:patientId/sheet/:documentId/edit',
        name: 'SurgeryMonitoringFormEdit',
        component: SurgeryMonitoringForm,
        meta: { edit: true }
      }
    ]
  },
  {
    path: '/app/:hospital/config',
    name: 'AppConfig',
    component: () => import(/* webpackChunkName: "config" */'@/components/config/Frame'),
    children: [
      {
        path: 'index',
        name: 'ConfigIndex',
        component: () => import(/* webpackChunkName: "config" */'@/components/config/AppConfigIndex')
      },
      {
        path: 'locations',
        name: 'LocationConfig',
        component: () => import(/* webpackChunkName: "config" */'@/components/config/LocationConfig')
      },
      {
        path: 'email',
        name: 'EmailConfig',
        component: () => import(/* webpackChunkName: "config" */'@/components/config/EmailConfigEditor')
      }
    ]
  },
  {
    path: '/app/:hospital/cultures',
    name: 'CultureArchive',
    component: () => import(/* webpackChunkName: "culture" */'@/components/cultures/Frame'),
    children: [
      {
        path: 'search',
        name: 'CultureSearch',
        component: () => import(/* webpackChunkName: "culture" */'@/components/cultures/Search')
      },
      {
        path: 'report/:id',
        name: 'CultureDetail',
        component: () => import(/* webpackChunkName: "culture" */'@/components/cultures/Detail')
      }
    ]
  },
  {
    path: '/app/:hospital/hand_hygiene/',
    name: 'HandHygiene',
    component: () => import(/* webpackChunkName: "culture" */'@/components/cultures/Frame'),
    children: [
      {
        path: 'observation',
        name: 'HandHygieneObservation',
        component: () => import(/* webpackChunkName: "culture" */'@/components/hand_hygiene/Observation')
      },
      {
        path: 'audit',
        name: 'HandHygieneAudit',
        component: () => import(/* webpackChunkName: "culture" */'@/components/hand_hygiene/Audit')
      }
    ]
  },
  {
    path: '/app/select_hospital',
    name: 'HospitalSelector',
    component: HospitalSelector
  },
  {
    path: '/app/login',
    name: 'AppLogin',
    component: AppLogin
  },
  { name: '404', path: '/app/404', component: NotFound },
  { path: '*', redirect: { name: '404' } }
]

const routes = baseRoutes.concat(
  covidChartRoutes,
  vaeRoutes
)

export default new Router({
  mode: 'history',
  routes: routes
})
