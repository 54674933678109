<template>
<div>
<h4>Patient Details</h4>
    <dl>
      <dt>PRN</dt><dd>{{ hospital_id }}</dd>
      <dt>Name</dt><dd>{{ name }}</dd>
    </dl>
    <p><strong>Authorization Requests</strong></p>
    <grid-table
      v-if="authorization_requests.length"
      :columns="grid_config.grid_columns"
      :options="grid_config.options"
      :table-data="authorization_requests">
      <router-link
        slot='id'
        slot-scope='props'
        :to="{name: 'RequestDetail', params:  {requestId: props.row.id, hospital: props.row.hospital }}"
        class="font-weight-bold">{{ props.row.id }}
      </router-link>
      <span slot="pharmacy_instruction" slot-scope="props">{{ props.row.pharmacy_instruction }}</span>
      <span slot="antibiotics" slot-scope='props'><span class="antibiotic-text" v-for="antibiotic in props.row.antibiotics" :key="antibiotic.antibiotic">{{ antibiotic.antibiotic }} x {{ antibiotic.duration }}d</span></span>
    </grid-table>
    <p v-else>No previous antibiotic authorization requests</p>
    <router-link :to="{name: 'RequestNew', params: {patientId: patient_id}}" class="btn btn-primary btn-sm">Submit a new request</router-link>
</div>
</template>
<script>
import axios from 'axios'
import router from '@/router'
import GridTable from '@/components/shared/datatable/Grid'
import CreateRouterMixin from '@/common/routerMixin'

var getData = function (r) {
  return axios.get(`/api/patients/hospital_id/${r.params.hospital_id}/`)
    // If server responds with 404, redirect to the Patient Create View
    .catch(error => {
      if (error.response.status === 404) {
        router.push({ name: 'PatientEdit', query: { hospital_id: r.params.hospital_id, onSuccess: r.path } })
      }
    })
}

var routerMixin = CreateRouterMixin(getData)

export default {
  name: 'RequestPatientDetail',
  mixins: [routerMixin],
  data: () => {
    return {
      name: null,
      hospital_id: null,
      patient_id: null,
      authorization_requests: [],
      grid_config: {
        grid_columns: ['id', 'request_time', 'antibiotics', 'ast_status', 'pharmacy_instruction'],
        options: {
          headings: {
            id: '#',
            antibiotics: 'Antibiotics',
            request_time: 'Time',
            ast_status: 'Review Status',
            pharmacy_instruction: 'Pharmacy Instruction'
          },
          dateColumns: ['request_time']
        }
      }
    }
  },
  methods: {
    setData (data) {
      this.name = data.name
      this.patient_id = data.id
      this.hospital_id = data.hospital_id
      this.authorization_requests = data.authorization_requests
    }
  },
  components: {
    'grid-table': GridTable
  }
}
</script>
<style scoped>
  span.antibiotic-text:after{
    content: ', ';
  }
  span.antibiotic-text:last-child:after{
    content: '';
  }
</style>
