<template>
  <div class="fs-container">
    <template v-for="f in computedSchema">
      <slot v-bind:name='f.key' v-bind:fscope="f">
      <component
        :is="getComponent(f)"
        :value="resolveValue(f.key)"
        v-bind="f"
        :fsLocation="f.key"
        @input="updateForm(f.key, $event)">
      </component>
      </slot>
    </template>
  </div>
</template>
<script>
// Stolen from the URL below!
// https://rangle.io/blog/how-to-create-data-driven-user-interfaces-in-vue/
// import Vue from 'vue'
import componentMap from './FSComponentMap'
import _ from 'lodash'
import { validationMixin } from 'vuelidate'
import * as vueValidators from 'vuelidate/lib/validators'
// import { extendSchemaWithInjected } from '@/components/shared/form-schema/FSInjectorMixin'
import { computed } from '@vue/composition-api'
import { unwrapReactiveSchema } from './input-components/FSReactiveSchemaUnwrapper'

export default {
  name: 'FormSchema',
  props: {
    schema: Array,
    value: Object
  },
  setup (props, context) {
    const computedSchema = computed(() => unwrapReactiveSchema(props.schema))

    const getComponent = f => _.get(componentMap, f.type, f.component)

    const updateForm = function (k, v) {
      _.set(props.value, k, v)
      context.emit('input', v)
    }

    const resolveValue = key => _.get(props.value, key)

    return {
      computedSchema,
      getComponent,
      updateForm,
      resolveValue
    }
  },
  provide () {
    return {
      fsValidator: () => this.$v
    }
  },
  validations () {
    function schemaToValidations (schema) {
      return _.reduce(schema, function (obj, value, key) {
        if (value.validators) {
          obj[value.key] = _.reduce(value.validators, function (validatorBag, validatorName) {
            validatorBag[validatorName] = vueValidators[validatorName]
            return validatorBag
          }, {})
        }

        if (!_.isUndefined(value.row_schema)) {
          obj[value.key] = {}
          obj[value.key].$each = schemaToValidations(value.row_schema)
        }
        return obj
      }, {})
    }
    const validationDef = {
      value: schemaToValidations(this.schema)
    }
    return validationDef
  },
  mixins: [validationMixin]
}
</script>
<style scoped>
.fs-field {
  margin-top: 15px;
  width: 100%;
}
.fs-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
</style>
