<template>
  <div v-if="patient.hospital_id">
    <h6>PROGRESS CHART</h6>
    <h4 class="d-block">{{ patient.name }} <span class="text-muted">#{{ patient.hospital_id }}</span></h4>
    <div class="actions mb-2">
      <router-link :to="{name: 'CovidChartFormEdit', params:  {patientId: patient.id, documentId: $route.params.documentId, hospital: currentHospital.code }}" class="btn btn-sm btn-outline-primary">Edit</router-link>
      <router-link :to="{name: 'TestArchive', params:  {hospitalId: patient.hospital_id, hospital: currentHospital.code }}" class="btn btn-sm btn-outline-primary">Lab Values</router-link>
    </div>

    <dl>
      <dt>Age</dt>
      <dd v-if="sheet.age">{{ sheet.age }} years</dd>
      <dd class='text-muted' v-else>[not recorded]</dd>
      <dt>Clinical Details</dt>
      <dd v-if='sheet.clinical_details'>{{ sheet.clinical_details }}</dd>
      <dd class='text-muted' v-else>[not recorded]</dd>
    </dl>

    <vert-grid-table
      :columns="chart_date_list"
      :options="{row_options: row_options}"
      :tableData="table_grid">
      <template slot='column_header'
        slot-scope='props'>
        {{ props.header | datetime }}
      </template>
      <template slot='max_temp'
        slot-scope='props'>
        {{ props.cell_value | fahrenheit }}
      </template>
      <template slot='ventilation_mode'
                slot-scope='props'>
        {{ ventilationModeShortener[props.cell_value] }}
      </template>
    </vert-grid-table>
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
// import { differenceInMinutes, parseISO } from 'date-fns'
import { mapGetters } from 'vuex'
import CreateRouterMixin from '@/common/routerMixin'
import detailRender from '@/components/shared/custom_widgets/detailRender'
import { parseISO, isWithinInterval } from 'date-fns'
import VertGridTable from './vertGrid'
import _ from 'lodash'

const loadData = route => {
  return axios.get(`ic_documents/hospital/${route.params.hospital}/covid_chart/${route.params.documentId}/`, axiosConfig)
}

const routerMixin = CreateRouterMixin(loadData)

const rowOptions = [
  { label: 'Hb', source: 'cbc.hemoglobin', value: 'hemoglobin' },
  { label: 'TLC', source: 'cbc.tlc', value: 'tlc' },
  { label: 'DLC', source: 'cbc.dlc', value: 'dlc' },
  { label: 'Platelets', source: 'cbc.platelets', value: 'platelets' },
  { label: 'BUN', source: 'biochem.bun', value: 'bun' },
  { label: 'Creatinine', source: 'biochem.creatinine', value: 'creatinine' },
  { label: 'Na', source: 'biochem.sodium', value: 'sodium' },
  { label: 'K', source: 'biochem.potassium', value: 'potassium' },
  { label: 'SGOT', source: 'biochem.ast', value: 'ast' },
  { label: 'SGPT', source: 'biochem.alt', value: 'alt' },
  { label: 'Bilirubin', source: 'biochem.total_bilirubin', value: 'total_bilirubin' },
  { label: 'Albumin', source: 'biochem.albumin', value: 'albumin' },
  { label: 'Absolute Lymphocyte', source: 'biomarkers.absolute_lympho', value: 'absolute_lympho' },
  { label: 'LDH', source: 'biomarkers.ldh', value: 'ldh' },
  { label: 'D-Dimer', source: 'biomarkers.d_dimer', value: 'd_dimer' },
  { label: 'Ferritin', source: 'biomarkers.ferritin', value: 'ferritin' },
  { label: 'Pro-BNP', source: 'biomarkers.pro_bnp', value: 'pro_bnp' },
  { label: 'CRP', source: 'biomarkers.crp', value: 'crp' },
  { label: 'COVID PCR', source: 'biomarkers.covid_pcr', value: 'covid_pcr' },
  { label: 'Max Temp', source: 'clinical_progress.max_temp', value: 'max_temp' },
  { label: 'Ventilation Mode', source: 'clinical_progress.ventilation_mode', value: 'ventilation_mode' },
  { label: 'Oxygen', source: 'clinical_progress.oxygen', value: 'oxygen' },
  { label: 'FiO2', source: 'clinical_progress.fio2', value: 'fiO2' }
]

const ventilationModeShortener = {
  spontaneous_ambient_air: 'room air',
  spontaneous_prongs: 'prongs',
  spontaneous_high_flow_oxygen: 'high flow o2',
  non_invasive_ventilation: 'NIV',
  invasive_ventilation_cmv: 'i-CMV',
  invasive_ventilation_simv: 'i-SIMV',
  invasive_ventilation_cpap_psv: 'i-CPAP'
}

export default {
  name: 'SSDetail',
  mixins: [routerMixin],
  data () {
    return {
      patient: { name: null, hospital_id: null },
      sheet: {
        age: null,
        clinical_details: null,
        date_of_admission: null,
        date_of_discharge: null,
        date_of_symptom_onset: null,
        comorbidities: [],
        cbc: [],
        biochem: [],
        biomarkers: [],
        drugs: [],
        clinical_progress: [],
        cultures: [],
        covid_pcr: [],
        remarks: null,
        metadata: {
          hmis_vmid: null
        }
      },
      grid_options: ['date', 'hemoglobin', 'tlc', 'dlc', 'platelets', 'creatinine'],
      row_options: _.cloneDeep(rowOptions),
      ventilationModeShortener: ventilationModeShortener
    }
  },
  computed: {
    ...mapGetters(['currentHospital']),
    chart_date_list: function () {
      const dateSet = _.map(this.get_grid, record => {
        const dates = _.map(record, v => {
          return v.date
        })
        return dates[0]
      })
      return _.sortBy(dateSet, d => {
        return parseISO(d)
      })
    },
    get_grid: function () {
      const dateGrid = {}
      const bags = ['cbc', 'biochem', 'biomarkers', 'covid_pcr', 'clinical_progress']
      _.forEach(bags, bag => {
        _.forEach(this.sheet[bag], record => {
          if (_.isUndefined(dateGrid[record.date])) {
            dateGrid[record.date] = {}
          }
          dateGrid[record.date][bag] = record
        })
      })
      return dateGrid
    },
    table_grid: function () {
      const data = {}
      _.forEach(this.row_options, option => {
        data[option.value] = {}
        _.forEach(this.chart_date_list, col => {
          data[option.value][col] = _.get(this.get_grid[col], option.source, null)
        })
      })

      // Drugs
      _.forEach(this.sheet.drugs, record => {
        data[record.drug] = {}
        this.row_options.push({ value: record.drug, label: record.drug })
        _.forEach(this.chart_date_list, col => {
          data[record.drug][col] = this.get_drug_status(record, col) ? '✓' : ''
        })
      })
      return data
    }
  },
  methods: {
    setData (data) {
      this.sheet = Object.assign(this.sheet, data.content)
      this.patient = Object.assign(this.patient, data.patient)
    },
    get_cell_value (date, source) {
      return _.get(date, source, null)
    },
    get_drug_status (record, date) {
      const start = parseISO(record.started)
      const end = parseISO(record.stopped)
      return isWithinInterval(parseISO(date), { start: start, end: end })
    }
  },
  components: {
    'vert-grid-table': VertGridTable,
    'detail-render': detailRender
  }
}
</script>
<style scoped>
table {
  font-size: 12px;
  width: auto;
}
thead th, tbody th {
  font-size: 12px;
}
span.ventilation_mode {
  font-size: 9px;
}
/deep/ .row_header {
  text-transform: uppercase;
  font-weight: 400;
}
</style>
