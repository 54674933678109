// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import * as VeeValidate from 'vee-validate'
import BootstrapVue from 'bootstrap-vue'
import store from './store'
import { sync } from 'vuex-router-sync'
import VueCompositionAPI from '@vue/composition-api'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'font-awesome/css/font-awesome.css'
import './assets/css/app.css'
import '@/common/filters.js'
import '@/registerServiceWorker'

/* eslint-disable no-unused-vars */
const unsync = sync(store, router)

Vue.config.productionTip = false
Vue.use(VueCompositionAPI)
Vue.use(BootstrapVue)
Vue.use(VeeValidate)

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  template: '<App/>',
  components: { App }
})
