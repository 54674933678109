import FSTextInput from './input-components/FSTextInput'
import FSSelectInput from './input-components/FSSelectInput'
import FSTextarea from './input-components/FSTextarea'
import FSCustomInput from './input-components/FSCustomInput'
import FSGrid from './input-components/FSGrid'
import FSDatepicker from './input-components/FSDatepicker'
import FSBoolean from './input-components/FSBoolean'

const componentMap = {
  text: FSTextInput,
  number: FSTextInput,
  boolean: FSBoolean,
  select: FSSelectInput,
  textarea: FSTextarea,
  datepicker: FSDatepicker,
  grid: FSGrid,
  'custom-input': FSCustomInput
}

export default componentMap
