<template>
  <app-frame>
    <template slot="main">
      <HospitalSwitcher></HospitalSwitcher>
      <router-view></router-view>
    </template>
  </app-frame>
</template>
<script>
import AppFrame from '@/components/AppFrame'
import HospitalSwitcher from '@/components/shared/HospitalSwitcher'
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'ICUSurveillance',
  components: {
    'app-frame': AppFrame,
    HospitalSwitcher
  },
  computed: {
    ...mapGetters(['currentHospital']),
    config () {
      return this.$store.state.issue_tracker_config
    }
  },
  mounted () {
    axios.get(`issue-tracker/hospital/${this.currentHospital.code}/config`, axiosConfig).then(response => {
      this.$store.commit('populate_issue_tracker_config', response.data)
    })
  }
}
</script>
