<template>
<div>
  <h4>Ventilator Associated Events</h4>
    <div class="form-group row">
      <div class="col-lg-4">
        <input type="text"
        class="form-control"
        placeholder="Search PRN or Name"
        v-model="searchTerm">
      </div>
    </div>
    <GridTable
      :table-data='tableData'
      :options='gridConfig.options'
      :columns="gridConfig.columns">
      <router-link
        slot='id'
        slot-scope='props'
        :to="{name: 'VAEFormEdit', params:  {patientId: props.row.patient.id, documentId: props.row.id, hospital: props.row.hospital }}">
        {{ props.row.id }}
      </router-link>
      <template
        slot='patient'
        slot-scope='props'>
        <router-link
          :to="{name: 'PatientDetail', params:  {hospitalId: props.row.patient.hospital_id, hospital: props.row.hospital }}">{{ props.row.patient.name }}</router-link> #{{ props.row.patient.hospital_id }}
      </template>
      <template
        slot='actions'
        slot-scope='props'>
        <i class='fa fa-trash-o' @click='deleteDocument(props.row.id)'></i>
      </template>
      <template slot='no_results'>
        No Sheets Found
      </template>
    </GridTable>
    <GridPagination
      :page="page"
      :route-link="{ name: currentRoute.name, query: currentRoute.query }"
      :count="resultCount">
    </GridPagination>
</div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import GridTable from '@/components/shared/datatable/Grid'
import GridPagination from '@/components/shared/datatable/GridPagination'
import CreateRouterMixin from '@/common/routerMixin'
import { ref, computed, watch } from '@vue/composition-api'
import router from '@/router'
import _ from 'lodash'

var getData = function (route) {
  return axios.get(`ic_documents/hospital/${route.params.hospital}/patient_documents/`,
    {
      ...axiosConfig,
      params: {
        ...route.query,
        document_type: 'vae_chart'
      }
    })
}

const routerMixin = CreateRouterMixin(getData)

export default {
  name: 'VAEList',
  props: {
    page: {
      type: Number,
      default: 1
    }
  },
  mixins: [routerMixin],
  setup (props, context) {
    const documentList = ref([])
    const gridConfig = {
      columns: ['id', 'patient', 'created', 'modified', 'vae_category'],
      options: {
        dateColumns: ['created', 'modified'],
        headings: {
          id: '#'
        }
      }
    }
    const currentRoute = computed(() => router.currentRoute)
    const resultCount = ref(0)
    const searchTerm = ref('')

    const setData = data => {
      documentList.value = data.results
      resultCount.value = data.count
    }

    const tableData = computed(() => {
      return documentList.value.map(doc => {
        const entry = _.pick(doc, ['id', 'patient', 'created', 'modified', 'hospital'])
        entry.vae_category = doc.content.chart_review.vae_category
        return entry
      })
    })

    watch(searchTerm, (newSearch, oldSearch) => {
      const route = { name: currentRoute.value.name, query: {} }

      // A number usually means hospital ID
      if (newSearch.match(/\d{4,10}/)) { route.query.patient__hospital_id = newSearch }

      // search syntax
      if (newSearch.match(/[A-Za-z]+/)) {
        route.query.search = newSearch
      }

      router.push(route)
    })

    const deleteDocument = async (id) => {
      const msg = 'Deleting document...'
      if (confirm(msg)) {
        await axios.delete(`ic_documents/hospital/${currentRoute.params.hospital}/vae_chart/${id}/`, axiosConfig)
        this.$store.dispatch('display_notification', {
          message: 'Successfully Deleted'
        })
        const response = await getData(currentRoute)
        setData(response.data)
      }
    }

    return {
      setData,
      tableData,
      resultCount,
      gridConfig,
      currentRoute,
      searchTerm,
      deleteDocument
    }
  },
  components: {
    GridTable,
    GridPagination
  }
}
</script>
