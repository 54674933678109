<template>
  <div class="col-md-6">
    <form novalidate @submit.prevent="validateAndSave()">
      <h4>Infection Control Finding</h4>
      <FormSchema :schema="formSchema" :value="formData" ref="useFormSchema"></FormSchema>
      <button class="mt-3 btn btn-primary">Save</button>
    </form>
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import router from '@/router'
import CreateRouterMixin from '@/common/routerMixin'
import Autocomplete from '@/components/shared/autocomplete'
import { mapGetters } from 'vuex'
import FormSchema from '@/components/shared/form-schema/FormSchema'
import { reactive, computed, ref } from '@vue/composition-api'

var loadData = r => {
  if (r.matched.some(record => record.meta.edit)) {
    return axios.get(`issue-tracker/hospital/${r.params.hospital}/tickets/${r.params.ticketId}/`, axiosConfig)
  } else {
    return new Promise(function (resolve, reject) {
      resolve({
        data: {
          datetime: new Date(),
          corrective_action: ''
        }
      })
    })
  }
}

const routerMixin = CreateRouterMixin(loadData)

const EmployeeAutocomplete = {
  name: 'EmployeeAutocomplete',
  props: ['value'],
  template: `<Autocomplete
        :value="state.employee"
        @search-change='searchEmployees'
        @input-cleared='state.employee = null'
        @update:value="state.employee = $event"
        :options="state.employeeChoices">
  ></Autocomplete>`,
  setup (props, { emit }) {
    const state = reactive({
      employeeChoices: [],
      employee: computed({
        get: () => props.value,
        set: val => {
          emit('input', val)
        }
      })
    })

    const searchEmployees = async (query) => {
      const response = await axios.get('issue-tracker/employees/search/?search=' + query, axiosConfig)
      state.employeeChoices = response.data.map(item => `${item.empName} - ${item.empId}`)
    }

    return {
      state,
      searchEmployees
    }
  },
  components: {
    Autocomplete
  }
}

export default {
  name: 'TicketEdit',
  mixins: [
    routerMixin
  ],
  setup (props, context) {
    const useFormSchema = ref(null)
    const departmentChoices = computed(() => context.root.$store.state.issue_tracker_config.department_choices)
    const categoryChoices = computed(() => context.root.$store.state.issue_tracker_config.category_choices)

    const locationChoices = computed(() => {
      return context.root.$store.state.issue_tracker_config.location_choices.map(optgroup => {
        return {
          label: optgroup.label,
          items: optgroup.locations
        }
      })
    })

    const formData = reactive({
      id: null,
      datetime: null,
      hospital: null,
      location: null,
      department: null,
      category: null,
      text: null,
      severity: 'normal',
      related_person: null,
      corrective_action: null
    })

    const formSchema = reactive([
      {
        type: 'select',
        label: 'Location',
        key: 'location',
        optgroups: true,
        options: locationChoices,
        validators: ['required']
      },
      {
        type: 'select',
        label: 'Category',
        key: 'category',
        options: categoryChoices,
        validators: ['required']
      },
      {
        type: 'select',
        label: 'Department',
        key: 'department',
        options: departmentChoices,
        validators: ['required']
      },
      {
        type: 'select',
        label: 'Severity',
        key: 'severity',
        options: [
          { value: 'low', label: 'Low' },
          { value: 'normal', label: 'Normal' },
          { value: 'high', label: 'High' },
          { value: 'critical', label: 'Critical' }
        ]
      },
      {
        type: 'textarea',
        label: 'Finding',
        key: 'text',
        rows: 4,
        validators: ['required']
      },
      {
        type: 'custom-input',
        label: 'Employee (Optional)',
        component: EmployeeAutocomplete,
        key: 'related_person'
      },
      {
        type: 'textarea',
        label: 'Corrective Action',
        key: 'corrective_action',
        rows: 4,
        validators: ['required']
      }
    ])

    const saveRequest = async () => {
      formData.hospital = context.root.$store.getters.currentHospital.id
      const { currentRoute } = router

      const axiosCall = isEdit => {
        return isEdit
          ? axios.put(`issue-tracker/hospital/${currentRoute.params.hospital}/tickets/${currentRoute.params.ticketId}/`, formData, axiosConfig)
          : axios.post(`issue-tracker/hospital/${currentRoute.params.hospital}/tickets/`, formData, axiosConfig)
      }

      const response = await axiosCall(currentRoute.meta.edit)
      router.push({ name: 'TicketDetail', params: { ticketId: response.data.id } })
    }

    const validateAndSave = async () => {
      useFormSchema.value.$v.$touch()
      if (useFormSchema.value.$v.$invalid) {
        return
      }
      await saveRequest()
    }

    return {
      formData,
      formSchema,
      validateAndSave,
      useFormSchema
    }
  },
  computed: {
    ...mapGetters([
      'currentHospital'
    ])
  },
  methods: {
    setData (data) {
      this.formData = Object.assign(this.formData, data)
    }
  },
  components: {
    FormSchema
  }
}
</script>
