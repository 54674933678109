<template>
  <div class="row no-gutters">
    <div class="col-6">
      <datepicker v-model='date' v-validate="'date_format:yyyy-MM-d'" data-vv-name="date" ref="datepicker" @input="updateDateTime()"></datepicker>
    </div>
    <div class="col-6">
      <input
        v-model="time"
        class="form-control"
        :class="{'is-invalid': fields.time && fields.time.touched && fields.time.invalid}"
        required
        v-validate
        name="time"
        @input="updateDateTime()"
        type='time'>
    </div>
  </div>
</template>
<script>
import Datepicker from '@/components/shared/datepicker'
import { format, parseISO, parse, isEqual, isValid } from 'date-fns'
// import _ from 'lodash'

export default {
  props: {
    value: [Date, String]
  },
  data () {
    return {
      date: null,
      time: null,
      internalVal: null
    }
  },
  components: {
    datepicker: Datepicker
  },
  mounted () {
    this.setDateTime(this.value)
  },
  methods: {
    updateDateTime () {
      try {
        this.internalVal = parse(`${this.date} ${this.time} +0530`, 'yyyy-MM-dd HH:mm X', new Date())
        this.$emit('update:value', this.internalVal.toISOString())
      } catch (e) {
      }
    },
    setDateTime (val) {
      const newVal = parseISO(val)
      if (isValid(newVal)) {
        this.internalVal = newVal
        this.date = format(newVal, 'yyyy-MM-dd')
        this.time = format(newVal, 'HH:mm')
      }
    }
  },
  watch: {
    value (newVal, oldVal) {
      if (!isEqual(parseISO(newVal), parseISO(oldVal))) {
        this.setDateTime(newVal)
      }
    }
  }
}
</script>
<style scoped>
.help-inline {
  padding-top: 6px;
  padding-left: 3px;
}
</style>
