<template>
<div>
<h4>Device Surveillance</h4>
    <div class="form-group row">
      <div class="col-lg-4">
        <input type="text"
        class="form-control"
        placeholder="Search PRN or Name"
        v-model="search_term">
      </div>
    </div>
    <grid-table
      :table-data='device_monitoring'
      :options='device_monitoring_grid.options'
      :columns="device_monitoring_grid.columns">
      <router-link
        slot='document_type'
        slot-scope='props'
        :to="{name: 'ICUSurveillanceSheet', params:  {patientId: props.row.patient.id, documentId: props.row.id, hospital: props.row.hospital }}">
        {{ props.row.document_type | snake_to_startcase }}
      </router-link>
      <span
        slot='patient'
        slot-scope='props'>
        <router-link
          :to="{name: 'SurveillancePatientDetail', params:  {hospitalId: props.row.patient.hospital_id, hospital: props.row.hospital }}">{{ props.row.patient.name }}</router-link> #{{ props.row.patient.hospital_id }}
      </span>
      <span
        slot='summary'
        slot-scope='props'>
        <span v-if="props.row.summary.has_central_catheter" class="badge badge-info">CVC</span>
        <span v-if="props.row.summary.vae" class="badge badge-warning">VAE</span>
      </span>
      <span
        slot='actions'
        slot-scope='props'>
        <i class='fa fa-trash-o' @click='delete_doc(props.row.id)'></i>
      </span>
      <span slot='no_results'>
        No Sheets Found
      </span>
    </grid-table>
    <grid-pagination
      :page="page"
      :route-link="{ name: $route.name, query: $route.query }"
      :count="result_count">
    </grid-pagination>
</div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { mapGetters } from 'vuex'
import GridTable from '@/components/shared/datatable/Grid'
import GridPagination from '@/components/shared/datatable/GridPagination'
import CreateRouterMixin from '@/common/routerMixin'

var getData = function (route) {
  return axios.get(`ic_documents/hospital/${route.params.hospital}/device_monitoring/`,
    Object.assign({ params: route.query }, axiosConfig)
  )
}

var routerMixin = CreateRouterMixin(getData)

export default {
  name: 'SurveillancePatientDetail',
  props: {
    page: {
      type: Number,
      default: 1
    }
  },
  mixins: [routerMixin],
  data: () => {
    return {
      device_monitoring: [],
      result_count: 0,
      device_monitoring_grid: {
        columns: ['document_type', 'patient', 'summary', 'created', 'modified', 'actions'],
        options: {
          dateColumns: ['created', 'modified'],
          headings: {
          }
        }
      },
      search_term: ''
    }
  },
  computed: {
    ...mapGetters(['currentHospital'])
  },
  watch: {
    search_term: function (newSearch, oldSearch) {
      var route = { name: this.$route.name, query: {} }

      // A number usually means hospital ID
      if (newSearch.match(/\d{4,10}/)) { route.query.patient__hospital_id = newSearch }

      // search syntax
      if (newSearch.match(/[A-Za-z]+/)) {
        route.query.search = newSearch
      }

      this.$router.push(route)
    }
  },
  methods: {
    setData (data) {
      this.device_monitoring = data.results
      this.result_count = data.count
    },
    delete_doc (id) {
      const msg = 'Deleting document...'
      if (confirm(msg)) {
        axios.delete(`ic_documents/hospital/${this.$route.params.hospital}/device_monitoring/${id}/`, axiosConfig).then(response => {
          this.$store.dispatch('display_notification', {
            message: 'Successfully Deleted'
          })
          getData(this.$router.currentRoute).then(response => {
            this.setData(response.data)
          })
        })
      }
    }
  },
  components: {
    'grid-table': GridTable,
    'grid-pagination': GridPagination
  }
}
</script>
<style scoped>
  span.antibiotic-text:after{
    content: ', ';
  }
  span.antibiotic-text:last-child:after{
    content: '';
  }
</style>
