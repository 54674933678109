<template>
  <div>
    <h4 class="mb-3">
      Antibiotic Authorization Request</h4>
    <dl>
      <dt>PRN</dt>
      <dd>{{ patient.hospital_id }}</dd>
      <dt>Name</dt>
      <dd>
      <router-link
        :to="{name: 'PatientDetail', params:  {hospital_id: patient.hospital_id }}">
        {{ patient.name }}
      </router-link>
      </dd>
      <dt>Hospital Bed Number</dt>
      <dd>{{ hospital_bed_number }}</dd>
      <dt>Request Time</dt>
      <dd>{{ request_time | datetime }}</dd>
      <dt>Requested by</dt>
      <dd>{{ clinician }}</dd>
      <dt>Indication</dt>
      <dd>{{ indication_category }}</dd>
      <dt>Clinical Details</dt>
      <dd>{{ clinical_details }}</dd>
      <dt>Antibiotics</dt>
      <dd><span v-for="(antibiotic, index) in antibiotics" :key="index">{{ antibiotic.antibiotic }} - {{ antibiotic.dose }} x {{ antibiotic.duration }} days<br></span></dd>
      <dt>HIC Review Status</dt>
      <dd>{{ ast_status }}</dd>
      <dt>HIC Review</dt>
      <dd class='ast_review' v-if="ast_review">{{ ast_review }}</dd>
      <dd v-else>N/A</dd>
    </dl>
    <div class="mt-5">
      <router-link :to="{name:'RequestEdit', params: { requestId: id, patientId: patient.id}}" class="btn btn-link">Edit</router-link>
      <router-link v-if="user.permissions.all_permissions.includes('antibiotic_authorization.authorize_antibioticrequest')" :to="{name:'RequestProcess', params: { requestId: id, patientId: patient.id}}" class="btn btn-link">Process</router-link>
      <a v-if="user.permissions.is_staff" class="btn btn-link" :href="'/admin/antibiotic_authorization/antibioticrequest/' + id + '/change'">View in Admin</a>
    </div>
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import CreateRouterMixin from '@/common/routerMixin'
import { mapGetters } from 'vuex'

var getData = function (route) {
  return axios.get(`hospital/${route.params.hospital}/antibiotic_authorization/${route.params.requestId}/`, axiosConfig)
}

var routerMixin = CreateRouterMixin(getData)

export default {
  name: 'RequestDetail',
  mixins: [routerMixin],
  data: () => {
    return {
      patient: {
        hospital_id: null,
        id: null,
        name: null
      },
      hospital_bed_number: null,
      id: null,
      request_time: null,
      clinician: null,
      indication_category: null,
      clinical_details: null,
      antibiotics: [],
      ast_status: null,
      ast_review: null
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    ...mapGetters(['currentHospital'])
  },
  methods: {
    setData (data) {
      Object.assign(this, data)
      this.patient = {
        id: data.patient.id,
        name: data.patient.name,
        hospital_id: data.patient.hospital_id
      }
    }
  }
}

</script>
<style scoped>
.ast_review {
  white-space: pre-wrap;
}
</style>
