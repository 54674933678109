import Frame from './Frame'
import Form from './Form'
import List from './List'

export default {
  path: '/app/:hospital/surveillance/vae',
  name: 'VAEChart',
  component: Frame,
  children: [
    {
      path: 'list',
      name: 'VAEList',
      component: List,
      props: (route) => (
        {
          page: typeof route.query.page === 'undefined' ? 1 : parseInt(route.query.page)
        }
      )
    },
    {
      path: 'patient/:patientId/sheet/new',
      name: 'VAEFormNew',
      component: Form,
      meta: { edit: false }
    },
    {
      path: 'patient/:patientId/sheet/:documentId/edit',
      name: 'VAEFormEdit',
      component: Form,
      meta: { edit: true }
    }
  ]
}
