<template>
  <div class="fs-field" :class="outer_class">
    <label>{{ label }}</label>
    <div class="form-check-positioner">
      <input type="checkbox" v-model="valueAccessor" class="form-check-inline" @blur="$fsV.$touch()">
    </div>
  </div>
</template>
<script>

import FSValidationMixin from '../FSValidationMixin'

export default {
  name: 'FSBooleanInput',
  props: [
    'label',
    'value',
    'outer_class'
  ],
  computed: {
    valueAccessor: {
      get () {
        return this.value
      },
      set (v) {
        this.$fsV.$touch()
        this.$emit('input', v)
      }
    }
  },
  mixins: [
    FSValidationMixin
  ]
}
</script>
<style scoped>
input.form-control.is-invalid {
  border-color: red!important;
  background-color: #FCE4EC;
}
.form-check-positioner {
  padding-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 34px;
}

</style>
