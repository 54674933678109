<template>
  <app-frame>
    <template slot="main-container">
      <div class="col-md-6 offset-md-3 p-5">
        <p class="font-weight-bold">Select Hospital</p>
        <div class="form-group">
        <select class="form-control form-control-sm"
                v-model="selected_hospital">
          <option v-for="hospital in hospitals_config"
                  v-bind:value="hospital"
                  :key="hospital.code">
          {{ hospital.name }}
          </option>
        </select>
        </div>
        <button class="btn btn-primary" v-if="selected_hospital" v-on:click="goToHospital()">Select</button>
      </div>
    </template>
  </app-frame>
</template>
<script>
import AppFrame from '@/components/AppFrame'
import FormControlWrapper from '@/components/shared/form_group'
import router from '@/router'
import { mapGetters } from 'vuex'
export default {
  name: 'HospitalSelector',
  data () {
    return {
      selected_hospital: null
    }
  },
  components: {
    'app-frame': AppFrame,
    'form-control': FormControlWrapper
  },
  computed: {
    ...mapGetters(['hospitals_config', 'getHospitalByCode'])
  },
  beforeMount () {
    const autodetectedHospital = this.getHospitalByCode(this.$store.state.user.permissions.inferred_location)
    if (typeof autodetectedHospital !== 'undefined' && this.$route.query.autodetect === '1') {
      router.push({
        name: 'RequestList',
        params: { hospital: autodetectedHospital.code }
      })
    }
  },
  methods: {
    goToHospital () {
      router.push({ name: 'RequestList', params: { hospital: this.selected_hospital.code } })
    }
  }
}
</script>
