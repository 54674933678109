<template>
  <div class="fs-field" :class="outer_class" @focusout="handleBlur">
    <label>{{ label }}</label>
    <Datepicker
       :input_class="{'is-invalid': $fsV.$dirty && $fsV.$invalid, 'form-control': true}"
       v-model="valueAccessor">
    </Datepicker>
  </div>
</template>
<script>
import FSValidationMixin from '../FSValidationMixin'
import Datepicker from '@/components/shared/datepicker'

export default {
  name: 'FSDatepicker',
  props: [
    'label',
    'value',
    'outer_class'
  ],
  computed: {
    valueAccessor: {
      get () {
        return this.value
      },
      set (v) {
        this.$fsV.$touch()
        this.$emit('input', v)
      }
    }
  },
  methods: {
    handleBlur () {
      this.$fsV.$touch()
    }
  },
  mixins: [
    FSValidationMixin
  ],
  components: {
    Datepicker
  }
}
</script>
<style scoped>
div >>> input.form-control.is-invalid {
  border: 1px solid red;
  background-color: #ffebee;
}
</style>
