<template>
  <div class="fs-field" :class="outer_class">
    <label>{{ label }}</label>
    <div class="grid">
      <form-list :value="computedValue"
             ref="formlist"
             row_class="row"
             @input="$emit('input', $event)"
             :default="row_default">

        <template slot="row" slot-scope="{ row, index, onchange }">
          <div
            class="row_wrapper"
            :class="{'expandedTab': index === expandedIndex }">
            <div
              class="d-md-none row-display-handle"
              :class="{'error': getRowValidation(index)}"
              v-on:click="setExpanded(index)">
              {{ getRowDisplayLabel(row) }}
            </div>
            <transition name="expandin">
            <div class="row_holder" v-show="!isMobileScreen || index === expandedIndex">
              <div
                v-for="cell_schema in computedRowSchema"
                :class="cell_schema.cell_class"
                class="col-md grid-cell">
                <component
                  :is="getComponent(cell_schema)"
                  :value="row[cell_schema.key]"
                  @input="update(row, index, cell_schema, $event)"
                  v-bind:rowContext="row"
                  v-bind:fsLocation="$attrs.fsLocation + '.' + index + '.' + cell_schema.key"
                  v-bind="cell_schema">
                </component>
              </div>
            </div>
            </transition>
          </div>
        </template>
      </form-list>
    </div>
  </div>
</template>
<script>
import componentMap from '../FSComponentMap'
import FSValidation from '../FSValidationCompositionAPI'
import FormList from '@/components/shared/FormList'
import _ from 'lodash'
// import { extendSchemaWithInjected } from '@/components/shared/form-schema/FSInjectorMixin'
import { ref, computed } from '@vue/composition-api'
import { unwrapReactiveSchema } from './FSReactiveSchemaUnwrapper'
import reactiveMediaQuery from '@/common/reactiveMediaQuery'

export default {
  name: 'FSGrid',
  props: {
    label: String,
    outer_class: String,
    value: [Array, Object],
    row_default: [Function, Object, Array],
    row_schema: Array
  },
  setup (props, context) {
    const formlist = ref(null) // See template
    const expandedIndex = ref(null)

    const computedRowSchema = computed(() => unwrapReactiveSchema(props.row_schema))

    const getComponent = f => _.get(componentMap, f.type, f.component)

    const update = (row, index, cellSchema, eventData) => {
      row[cellSchema.key] = eventData // Note vue reactivity limitations
      formlist.value.onChange()
    }

    const emitInput = (val) => {
      context.emit('input', _.omit(val, '_row_class'))
    }

    const computedValue = computed(() => {
      return props.value.map((r, index) => {
        return r
      })
    })

    const setExpanded = index => {
      expandedIndex.value = expandedIndex.value === index
        ? null
        : index
    }

    const getRowDisplayLabel = row => {
      if (context.attrs.row_label) {
        return context.attrs.row_label(row)
      }
      // Fallback
      const k = computedRowSchema.value[0].key
      const label = row[k] ? row[k] : '?'
      return label
    }

    const isMobileScreen = reactiveMediaQuery('(max-width: 767px)')

    const validation = FSValidation(context.attrs)

    const getRowValidation = index => {
      const rowValidation = validation.$each.$iter[index]
      return rowValidation.$dirty && rowValidation.$invalid
    }

    return {
      computedRowSchema,
      computedValue,
      getComponent,
      update,
      emitInput,
      formlist,
      expandedIndex,
      setExpanded,
      getRowDisplayLabel,
      getRowValidation,
      isMobileScreen
    }
  },
  components: {
    'form-list': FormList
  }
}
</script>
<style scoped>
/* TODO fix this relative path */
@import '../../grid.css'
</style>
<style scoped>
</style>
