<template>
  <div>
    Please wait while we find if the chart exists!
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import AppFrame from '@/components/AppFrame'
import router from '@/router'
export default {
  name: 'RedirectToCovidChart',
  data () {
    return {
    }
  },
  components: {
    'app-frame': AppFrame
  },
  computed: {
  },
  mounted () {
    const hospitalId = this.$route.params.hospitalId
    axios.get(`ic_documents/hospital/${this.$route.params.hospital}/covid_chart/get_chart_by_hospital_id?hospital_id=${hospitalId}`, axiosConfig)
      .then(response => {
        router.push({
          name: 'CovidChartDetail',
          params: {
            hospital: this.$route.params.hospital,
            patientId: response.data.patient_id,
            documentId: response.data.chart_id
          }
        })
      })
      .catch(err => {
        console.log(err)
        this.$store.dispatch('display_notification', {
          message: 'Chart Not Found. 404',
          type: 'error'
        })

        router.push({
          name: 'CovidChartList',
          params: {
            hospital: this.$route.params.hospital
          }
        })
      })
  }
}
</script>
