<template>
  <autocomplete
    :value.sync="organism"
    :get-suggestion-label='o => o.code + " - " + o.name'
    :get-input-text='o => o.code ? o.code + " - " + o.name : null'
    @search-change='getMicrobeSuggestions'
    @input-cleared='organism = { name: null, code: null }'
    @update:value='onchange'
    placeholder='NEGATIVE'
    :options="microbe_list">
    <span slot="no_search_results">No search results</span>
  </autocomplete>
</template>

<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import Autocomplete from '@/components/shared/autocomplete'

var COMMON_PATHOGENS = [
  {
    name: 'Staphylococcus aureus',
    code: 'SA'
  },
  {
    name: 'Escherichia coli',
    code: 'EC'
  },
  {
    name: 'Klebsiella pneumoniae',
    code: 'KP'
  },
  {
    name: 'Pseudomonas aeruginosa',
    code: 'PA'
  },
  {
    name: 'Acinetobacter baumanii',
    code: 'ACBA'
  }
]

export default {
  name: 'OrganismSelect',
  props: {
    value: {
      required: true
    }
  },
  data () {
    return {
      organism: {
        name: this.value.name,
        code: this.value.code
      },
      microbe_list: COMMON_PATHOGENS
    }
  },
  methods: {
    getMicrobeSuggestions (q) {
      if (q.length >= 3) {
        axios.get(`config/microbe/?search=${q}`, axiosConfig).then(response => {
          this.microbe_list = response.data.results
        })
      } else {
        this.microbe_list = COMMON_PATHOGENS
      }
    },
    onchange () {
      this.$emit('input', this.organism)
    }
  },
  components: {
    autocomplete: Autocomplete
  }

}
</script>
