<template>
<div>
  <table class="table table-sm table-responsive-sm">
    <thead>
      <tr>
        <th v-for="header in table_headers">{{ header | snake_to_startcase }}</th>
      </tr>
    </thead>
    <template v-if='tableData.length'>
      <tr v-for="row in tableData">
        <td v-for="col in columns">
          <slot :name="col" :row="row">
          <template v-if="date_columns[col]">{{ row[col] | datetime }}</template>
          <template v-else>{{ getCellValue(row, col) }}</template>
          </slot>
        </td>
      </tr>
    </template>
    <tr v-else>
      <td class='noResults' :colspan='columns.length'><slot name='no_results'>0 results</slot></td>
    </tr>
  </table>
</div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'GridTable',
  props: {
    columns: {
      type: Array
    },
    options: {
      type: Object
    },
    tableData: {
      type: Array
    },
    pagination: {
      type: Object
    }
  },
  data () {
    return {}
  },
  methods: {
    isDate (col) {
      return this.options.date_columns.indexOf(col) > -1
    },
    getCellValue (row, key) {
      return _.get(row, key)
    }
  },
  computed: {
    computed_options () {
      const defaultOptions = {
        headings: {
        },
        date_columns: {
        }
      }
      return Object.assign(defaultOptions, this.options)
    },
    table_headers () {
      return this.columns.map(column => {
        return _.isUndefined(this.computed_options.headings[column])
          ? column
          : this.computed_options.headings[column]
      })
    },
    date_columns () {
      const dateColumns = _.isUndefined(this.options.dateColumns) ? [] : this.options.dateColumns
      const dtConfig = {}
      _.forEach(this.columns, function (value, key) {
        dtConfig[value] = dateColumns.indexOf(value) > -1
      })
      return dtConfig
    }
  }
}
</script>
<style scoped>
.noResults {
  text-align: center;
  border-bottom: 1px solid lightgray;
}

thead th {
  text-transform: uppercase;
  font-size: 12px;
  border-top: 0px;
  background-color: #EEE;
}
</style>
