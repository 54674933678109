import Vue from 'vue'
import { format, parseISO } from 'date-fns'
import _ from 'lodash'

Vue.filter('datetime', function (dt) {
  try {
    if (dt.length === 10) {
      // We want a DATE
      return format(parseISO(dt), 'dd MMM yyyy')
    }
    // We want a DATETIME
    return format(parseISO(dt), 'dd MMM yyyy, HH:mm')
  } catch (err) {
    return null
  }
})

Vue.filter('snake_to_startcase', function (str) {
  try {
    return _.startCase(str.replace('_', ' '))
  } catch (e) {
    return str
  }
})

Vue.filter('titlecase', function (str) {
  return _.startCase(_.lowerCase(str))
})

Vue.filter('fahrenheit', function (num) {
  if (num === null) {
    return null
  }
  return _.round(num * 1.8 + 32, 1)
})
