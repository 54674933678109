<template>
  <app-frame>
    <div slot='sidebar'>
      <li>
        <router-link class="nav-link"
          active-class="active"
          :to="{ name: 'SurgeryMonitoringList', query: {status: 'added'}}"
          exact>
          <i class="fa fa-fw fa-list"></i>List</router-link>
      </li>
      <li>
        <router-link class="nav-link"
          active-class="active"
          :to="{ name: 'SurgeryMonitoringList', query: {status: 'pending'}}"
          exact>
          <i class="fa fa-fw fa-clock-o"></i>Pending</router-link>
      </li>
      <li>
        <router-link class="nav-link"
          active-class="active"
          :to="{ name: 'SurgeryMonitoringList', query: {status: 'deleted'}}"
          exact>
          <i class="fa fa-fw fa-trash"></i>Trash</router-link>
      </li>
      <li>
        <router-link class="nav-link"
          active-class="active"
          :to="{ name: 'SurgeryMonitoringDataExport'}"
          exact>
          <i class="fa fa-fw fa-download"></i>Export Data</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link" active-class="active" :to="{ name: 'SurgeryMonitoringStart', params: { hospital: currentHospital.code }, query: { nextView: 'SurgeryPatientDetail' }}">
          <i class="fa fa-fw fa-plus"></i>New Sheet</router-link>
      </li>
    </div>
    <template slot="main">
      <HospitalSwitcher></HospitalSwitcher>
      <router-view></router-view>
    </template>
  </app-frame>
</template>
<script>
import AppFrame from '@/components/AppFrame'
import HospitalSwitcher from '@/components/shared/HospitalSwitcher'
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { mapGetters } from 'vuex'

export default {
  name: 'ICUSurveillance',
  components: {
    'app-frame': AppFrame,
    HospitalSwitcher
  },
  computed: {
    ...mapGetters(['currentHospital']),
    config () {
      return this.$store.state.issue_tracker_config
    }
  },
  mounted () {
    axios.get(`issue-tracker/hospital/${this.currentHospital.code}/config`, axiosConfig).then(response => {
      this.$store.commit('populate_issue_tracker_config', response.data)
    })
  }
}
</script>
