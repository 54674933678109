<template>
    <div>
        <form>
            <h4 class="mb-4">New Sheet: Enter PRN</h4>
            <div class="form-inline">
                <label class="mb-2 mr-2">PRN</label>
                <input type="text" name="hospital_id" class="form-control mb-2 mr-2" id="hospital_id" placeholder="Enter Patient PRN" v-model="hospital_id">
                <button class="mb-2 btn btn-primary" v-on:click.stop.prevent="check_hospital_id()">Next</button>
            </div>
        </form>
    </div>
</template>

<script>
import router from '@/router'
export default {
  name: 'SurgeryMonitoringStart',
  data () {
    return {
      hospital_id: null
    }
  },
  methods: {
    check_hospital_id () {
      router.push({ name: this.$route.query.nextView, params: { hospitalId: this.hospital_id } })
    }
  }
}

</script>
