// https://stackoverflow.com/a/63944126
import { onMounted, ref } from '@vue/composition-api'

export default function (query) {
  const mq = window.matchMedia(query)
  const mediaQuery = ref(mq.matches)

  onMounted(() => mq.addEventListener('change', setMq))

  // onUnmounted(() => mq.removeListener(setMq))
  const setMq = e => {
    mediaQuery.value = e.matches
  }

  return mediaQuery
}
