<template>
  <div>
    <h4>Authorization Statistics: {{ stat_period }}</h4>
    <table class="table table-sm table-bordered">
      <thead>
        <tr><th colspan=2>Statistics</th></tr>
      </thead>
      <tbody>
        <tr>
          <td>Number of antibiotic requests</td>
          <td>{{ stats.number_of_requests }}</td>
        </tr>
        <tr>
          <td>Approved</td>
          <td>{{ stats.number_of_requests - stats.ast_choice_counts['Rejected'] - stats.ast_choice_counts['Disagreement'] }}</td>
        </tr>
        <tr>
          <td>Rejected</td>
          <td>{{ stats.ast_choice_counts['Rejected'] }}</td>
        </tr>
        <tr>
          <td>Disagreement</td>
          <td>{{ stats.ast_choice_counts['Disagreement'] }}</td>
        </tr>
        <tr>
          <td>Empirical Therapy</td>
          <td>{{ stats.indications_stats['empirical'] }}</td>
        </tr>
        <tr>
          <td>Culture Appropriate</td>
          <td>{{ stats.indications_stats['culture_appropriate'] }}</td>
        </tr>
      </tbody>
    </table>
    <table class="table table-bordered table-sm">
      <thead>
        <tr>
          <th>Antibiotic</th>
          <th>Number of requests</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(number_of_forms, antibiotic) in stats.antibiotic_stats" class="table table-bordered table-sm">
          <td>{{ antibiotic }}</td><td>{{ number_of_forms }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { format } from 'date-fns'
import CreateRouterMixin from '@/common/routerMixin'

function getData (r) {
  return axios.get(`hospital/${r.params.hospital}/antibiotic_authorization/statistics/${r.params.year}/${r.params.month}/`, axiosConfig)
}

var routerMixin = CreateRouterMixin(getData)

export default {
  name: 'RequestStatistics',
  mixins: [routerMixin],
  data: () => {
    return {
      stats: {
        number_of_requests: 0,
        ast_choice_counts: {
          Approved: 0,
          'Provisional Approval': 0,
          Rejected: 0,
          Disagreement: 0,
          Pending: 0,
          'Non-Compliance': 0
        },
        indications_stats: {
          empirical: 0,
          culture_appropriate: 0
        },
        antibiotic_stats: []
      }
    }
  },
  computed: {
    processed_stats () {
      return this.stats.number_of_requests - this.stats.ast_choice_counts.Pending
    },
    approved () {
      return this.stats.ast_choice_counts.Approved + this.stats.ast_choice_counts['Provisional Approval']
    },
    stat_period () {
      return format(new Date(this.$route.params.year, this.$route.params.month - 1, 1), 'MMMM yyyy')
    }
  },
  methods: {
    setData (data) {
      this.stats = data
    }
  }
}
</script>
