<template>
    <div>
        <form>
            <h4 class="mb-4">Download Surgery Monitoring Data</h4>
              <form-control label='Start Date'>
                <Datepicker
                  v-model='startDate'
                  name='startDate'
                  v-validate="{required: true}"></Datepicker>
              </form-control>
              <form-control label='End Date'>
                <Datepicker
                  v-model='endDate'
                  name='endDate'
                  v-validate="{required: true}"></Datepicker>
              </form-control>
              <div class='mt-3'>
                <button
                  :disabled="$v.startDate.$invalid || $v.endDate.$invalid"
                  class="mb-2 btn btn-primary"
                  v-on:click.stop.prevent="download_excel()">Download Excel File</button>
                <button
                  :disabled="$v.startDate.$invalid || $v.endDate.$invalid"
                  class="mb-2 btn btn-secondary"
                  v-on:click.stop.prevent="preview()">Preview</button>
              </div>
        </form>
        <div class='my-3'>
          <grid-table
            v-if='resp.length > 0'
            :table-data='resp'
            :columns='device_monitoring_grid.columns'
            :options='device_monitoring_grid.options'>
          </grid-table>
        </div>
    </div>
</template>

<script>
import Datepicker from '@/components/shared/datepicker'
import FormControlWrapper from '@/components/shared/form_group'
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import GridTable from '@/components/shared/datatable/Grid'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'SurgeryMonitoringDataExport',
  data () {
    return {
      startDate: null,
      endDate: null,
      resp: [],
      device_monitoring_grid: {
        columns: ['hospital_id', 'patient_name', 'start', 'surgery', 'surgery_class', 'duration', 'antibiotic_dose_difference', 'antibiotics'],
        options: {}
      }
    }
  },
  methods: {
    async download_excel () {
      window.open(`/api/ic_documents/hospital/${this.$route.params.hospital}/surgical_site_monitoring/export_data/?startDate=${this.startDate}&endDate=${this.endDate}&format=xlsx`)
    },
    async preview () {
      const resp = await axios.get(`ic_documents/hospital/${this.$route.params.hospital}/surgical_site_monitoring/export_data/?startDate=${this.startDate}&endDate=${this.endDate}`, axiosConfig)
      this.resp = resp.data
    }
  },
  validations: {
    startDate: { required },
    endDate: { required }
  },
  components: {
    Datepicker,
    'form-control': FormControlWrapper,
    'grid-table': GridTable
  },
  mixins: [validationMixin]
}

</script>
