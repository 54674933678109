<template>
  <div class="form1">
    <form novalidate @submit.prevent="validateAndSave()">
      <h4>COVID Chart</h4>
      <form-control label="Patient">
        <p class="font-weight-bold d-inline-flex p-2 bg-light form-control-plaintext">{{ patient.name }} (#{{ patient.hospital_id }})</p>
      </form-control>
      <form-schema :schema="formSchema" :value="sheet" ref="fs">
      </form-schema>
      <button class='btn btn-primary'
              @click.prevent='validateAndSave()'>Save</button>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import axiosConfig from '@/common/axiosConfig'
import { mapGetters } from 'vuex'
import router from '@/router'
import CreateRouterMixin from '@/common/routerMixin'
import _ from 'lodash'
import FormControlWrapper from '@/components/shared/form_group'
import Fahrenheit from '@/components/shared/fahrenheit.vue'
import OrganismSelect from '@/components/shared/custom_widgets/organismSelect.vue'
import FormSchema from '@/components/shared/form-schema/FormSchema'
import { reactive, onMounted, computed } from '@vue/composition-api'

var loadData = route => {
  if (route.matched.some(record => record.meta.edit)) {
    return axios.get(`ic_documents/hospital/${route.params.hospital}/covid_chart/${route.params.documentId}/`, axiosConfig)
  } else {
    return axios.get(`patients/${route.params.patientId}/`, axiosConfig)
  }
}

var routerMixin = CreateRouterMixin(loadData)

const SPECIMEN_LIST = ['blood', 'urine', 'csf', 'sputum', 'tracheal_aspirate', 'bronchoalveolar_lavage', 'pus', 'tissue', 'stool', 'other']
const COVID_SPECIMEN_LIST = ['nasopharyngeal_swab', 'oropharyngeal_swab', 'sputum', 'endotracheal_aspirate', 'bronchoalveolar_lavage']

function Drug () {
  this.drug = null
  this.started = null
  this.stopped = null
  this.route = null
}

function CompleteBloodCount () {
  this.date = null
  this.hemoglobin = null
  this.tlc = null
  this.dlc = null
  this.platelets = null
  this.organism = {
    code: null,
    name: null
  }
  this.auto_import = null
}

function Biochem () {
  this.date = null
  this.bun = null
  this.creatinine = null
  this.alt = null
  this.ast = null
  this.total_bilirubin = null
  this.sodium = null
  this.potassium = null
  this.albumin = null
  this.auto_import = null
}

function Biomarker () {
  this.date = null
  this.crp = null
  this.ldh = null
  this.ferritin = null
  this.d_dimer = null
  this.pro_bnp = null
  this.absolute_lympho = null
  this.covid_pcr = null
  this.auto_import = null
}

function ClinicalObservation () {
  this.date = null
  this.max_temp = null
  this.ventilation_mode = null
  this.oxygen = null
  this.fio2 = null
  this.cxr = null
}

function CultureEvent () {
  this.date = null
  this.specimen = null
  this.site = null
  this.organism = {
    code: null,
    name: null
  }
  this.remark = null
}

export default {
  name: 'CovidSheet',
  mixins: [
    routerMixin
  ],
  setup (props, context) {
    const antibioticOptions = computed(() => {
      return context.root.$store.state.antibiotic_list.map(i => {
        return {
          value: i,
          label: i
        }
      })
    })
    const formSchema = reactive([
      {
        type: 'number',
        label: 'Age',
        placeholder: 'Age',
        key: 'age',
        validators: ['required', 'decimal']
      },
      {
        type: 'textarea',
        label: 'Clinical Details',
        key: 'clinical_details',
        rows: 4
      },
      {
        type: 'datepicker',
        label: 'Date of Admission',
        key: 'date_of_admission',
        outer_class: 'datepicker_container',
        validators: ['required']
      },
      {
        type: 'datepicker',
        label: 'Date of Symptom Onset',
        key: 'date_of_symptom_onset',
        outer_class: 'datepicker_container'
      },
      {
        type: 'datepicker',
        label: 'Date of Discharge',
        key: 'date_of_discharge',
        outer_class: 'datepicker_container'
      },
      {
        type: 'grid',
        label: 'Clinical Progress',
        key: 'clinical_progress',
        row_default: () => new ClinicalObservation(),
        row_schema: [
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date',
            validators: ['required']
          },
          {
            type: 'custom-input',
            component: Fahrenheit,
            label: 'Max Temp (F)',
            key: 'max_temp'
          },
          {
            type: 'select',
            label: 'Ventilation Mode',
            key: 'ventilation_mode',
            options: [
              { label: 'Spontaneous - Room Air', value: 'spontaneous_ambient_air' },
              { label: 'Spontaneous - Nasal Prongs', value: 'spontaneous_prongs' },
              { label: 'Spontaneous - High Flow Oxygen / Non Rebreathing Mask', value: 'spontaneous_high_flow_oxygen' },
              { label: 'Non Invasive Ventilation', value: 'non_invasive_ventilation' },
              { label: 'Invasive Ventilation - CPAP/PSV', value: 'invasive_ventilation_cpap_psv' },
              { label: 'Invasive Ventilation - SIMV', value: 'invasive_ventilation_simv' },
              { label: 'Invasive Ventilation - CMV', value: 'invasive_ventilation_cmv' }
            ]
          },
          {
            type: 'text',
            label: 'O2 flow',
            key: 'oxygen'
          },
          {
            type: 'number',
            label: 'FiO2',
            key: 'fio2'
          },
          {
            type: 'text',
            label: 'Chest Xray',
            key: 'cxr'
          }
        ]
      },
      {
        type: 'grid',
        label: 'Complete Blood Count',
        key: 'cbc',
        row_default: () => new CompleteBloodCount(),
        row_schema: [
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date',
            validators: ['required']
          },
          {
            type: 'number',
            label: 'Hemoglobin',
            key: 'hemoglobin'
          },
          {
            type: 'number',
            label: 'TLC',
            key: 'tlc'
          },
          {
            type: 'text',
            label: 'Differential',
            key: 'dlc',
            placeholder: 'eg N75L22E3'
          },
          {
            type: 'number',
            label: 'Platelets',
            key: 'platelets'
          }
        ]
      },
      {
        type: 'grid',
        label: 'Biochemistry',
        key: 'biochem',
        row_default: () => new Biochem(),
        row_schema: [
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date',
            validators: ['required']
          },
          {
            type: 'number',
            label: 'BUN',
            key: 'bun'
          },
          {
            type: 'number',
            label: 'Creatinine',
            key: 'creatinine'
          },
          {
            type: 'number',
            label: 'SGPT',
            key: 'alt'
          },
          {
            type: 'number',
            label: 'SGOT',
            key: 'ast'
          },
          {
            type: 'number',
            label: 'Bilirubin',
            key: 'total_bilirubin'
          },
          {
            type: 'number',
            label: 'Na',
            key: 'sodium'
          },
          {
            type: 'number',
            label: 'K',
            key: 'potassium'
          }
        ]
      },
      {
        type: 'grid',
        label: 'Biomarkers',
        key: 'biomarkers',
        row_default: () => new Biomarker(),
        row_schema: [
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date',
            validators: ['required']
          },
          {
            type: 'select',
            key: 'covid_pcr',
            label: 'COVID PCR',
            options: [
              { label: 'POSITIVE', value: 'Positive' },
              { label: 'NEGATIVE', value: 'Negative' },
              { label: 'INCONCLUSIVE', value: 'Inconclusive' }
            ]
          },
          {
            type: 'number',
            key: 'crp',
            label: 'CRP'
          },
          {
            type: 'number',
            key: 'ldh',
            label: 'LDH'
          },
          {
            type: 'number',
            key: 'ferritin',
            label: 'Ferritin'
          },
          {
            type: 'number',
            key: 'd_dimer',
            label: 'D-Dimer'
          },
          {
            type: 'number',
            key: 'pro_bnp',
            label: 'Pro-BNP'
          },
          {
            type: 'number',
            key: 'absolute_lympho',
            label: 'Lymphocyte Count'
          }
        ]
      },
      {
        type: 'grid',
        key: 'drugs',
        label: 'Treatment',
        row_default: () => new Drug(),
        row_schema: [
          {
            key: 'drug',
            label: 'Drug',
            type: 'select',
            options: antibioticOptions,
            /*
            inject: {
              antibiotic_list_options: {prop: 'options'}
            },
            */
            validators: ['required']
          },
          {
            type: 'datepicker',
            label: 'Started',
            key: 'started'
          },
          {
            type: 'datepicker',
            label: 'Stopped',
            key: 'stopped'
          },
          {
            type: 'select',
            key: 'route',
            label: 'Route',
            options: [
              { label: 'IV', value: 'IV' },
              { label: 'PO', value: 'PO' },
              { label: 'IM', value: 'IM' },
              { label: 'SC', value: 'SC' }
            ]
          }
        ]
      },
      {
        type: 'grid',
        key: 'cultures',
        label: 'Cultures',
        row_default: () => new CultureEvent(),
        row_schema: [
          {
            type: 'datepicker',
            label: 'Date',
            key: 'date',
            validators: ['required']
          },
          {
            type: 'select',
            label: 'Specimen',
            key: 'specimen',
            options: _.map(SPECIMEN_LIST, item => { return { value: item, label: item } })
          },
          {
            type: 'text',
            label: 'Site',
            key: 'site'
          },
          {
            type: 'custom-input',
            component: OrganismSelect,
            label: 'Organism',
            key: 'organism'
          },
          {
            type: 'text',
            label: 'Remarks',
            key: 'remark'
          }
        ]
      }
    ])

    const sheet = reactive({
      age: null,
      clinical_details: null,
      date_of_admission: null,
      date_of_discharge: null,
      date_of_symptom_onset: null,
      comorbidities: [],
      cbc: [],
      biochem: [],
      biomarkers: [],
      drugs: [],
      clinical_progress: [],
      cultures: [],
      covid_pcr: [],
      remarks: null,
      metadata: {
        hmis_vmid: null
      }
    })

    onMounted(() => {
    })

    return {
      formSchema,
      sheet
    }
  },
  data: function () {
    return {
      patient: { name: null, hospital_id: null },
      specimen_list: SPECIMEN_LIST,
      covid_specimen_list: COVID_SPECIMEN_LIST
    }
  },
  computed: {
    ...mapGetters(['currentHospital'])
  },
  methods: {
    setData (data) {
      if (this.$route.meta.edit) {
        this.sheet = Object.assign(this.sheet, data.content)
        this.patient = Object.assign(this.patient, data.patient)
      } else {
        this.patient = Object.assign(this.patient, _.pick(data, ['id', 'name', 'hospital_id']))
      }
    },
    async validateAndSave () {
      this.$refs.fs.$v.$touch()
      if (this.$refs.fs.$v.$invalid) {
        return
      }
      this.saveSheet()
    },
    async saveSheet () {
      const data = {
        document_type: 'covid_chart',
        content: this.sheet,
        patient: this.$route.params.patientId,
        hospital: this.currentHospital.id
      }
      const formConfig = {
        put: {
          url: `ic_documents/hospital/${this.$route.params.hospital}/covid_chart/${this.$route.params.documentId}/`,
          method: 'put'
        },
        post: {
          url: `ic_documents/hospital/${this.$route.params.hospital}/covid_chart/`,
          method: 'post'
        }
      }
      const pick = this.$route.meta.edit ? formConfig.put : formConfig.post
      const response = await axios[pick.method](pick.url, data, axiosConfig)
      this.$store.dispatch('display_notification', {
        message: 'Successfully Saved'
      })
      router.push({
        name: 'CovidChartDetail',
        params: {
          hospital: this.currentHospital.code,
          patientId: this.$route.params.patientId,
          documentId: response.data.id
        }
      })
    }
  },
  components: {
    'form-control': FormControlWrapper,
    'form-schema': FormSchema
  }
}
</script>

<style scoped>
@media (min-width: 768px) {
  .form1 >>> .datepicker_container {
    width: 33%!important;
    padding: 10px;
  }
}
</style>
