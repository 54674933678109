<template>
  <input
    type='number'
    v-model.number='fahrenheit'
    step='0.1'
    class='form-control'>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'fahrenheitInput',
  props: {
    value: Number
  },
  computed: {
    fahrenheit: {
      get () {
        return this.celciusToFahrenheit(this.value)
      },
      set (val) {
        this.$emit('input', this.fahrenheitToCelcius(val))
      }
    }
  },
  methods: {
    celciusToFahrenheit (num) {
      if (num === null) {
        return null
      }
      return _.round(num * 1.8 + 32, 1)
    },
    fahrenheitToCelcius (num) {
      return _.round((num - 32) / 1.8, 2)
    }
  }
}
</script>
