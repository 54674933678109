<template>
    <table class='table table-bordered table-sm table-responsive-sm'>
      <thead>
        <tr>
          <th>#</th>
          <th v-for="header in columns" class='column_header'>
            <slot name='column_header' :header='header'>
            {{ header }}
            </slot>
          </th>
          <template v-if="columns.length < minColNumber">
            <th v-for="n in (minColNumber - columns.length)" :key="n" class="empty_cell">
            </th>
          </template>
        </tr>
      </thead>
      <tbody>
        <tr v-for='row in options.row_options'>
          <th class='row_header'>
            <slot name='row_header' :row_option='row'>
            {{ row.label }}
            </slot>
          </th>
          <td v-for='col in columns'>
            <slot :name='row.value' :cell_value='tableData[row.value][col]'>
            {{ tableData[row.value][col] }}
            </slot>
          </td>
          <template v-if="columns.length < minColNumber">
            <td v-for="n in (minColNumber - columns.length)" :key="n" class="empty_cell">
            </td>
          </template>
        </tr>
      </tbody>
    </table>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'VertGridTable',
  props: {
    columns: {
      type: Array
    },
    options: {
      type: Object,
      default: function () {
        return {
          headings: {},
          date_columns: {},
          row_options: {}
        }
      }
    },
    tableData: {
      type: Object
    },
    minColNumber: {
      default: 6
    }
  },
  data () {
    return {}
  },
  methods: {
    isDate (col) {
      return this.options.date_columns.indexOf(col) > -1
    }
  },
  computed: {
    date_columns () {
      const dateColumns = _.isUndefined(this.options.dateColumns) ? [] : this.options.dateColumns
      const dtConfig = {}
      _.forEach(this.columns, function (value, key) {
        dtConfig[value] = dateColumns.indexOf(value) > -1
      })
      return dtConfig
    }
  }
}
</script>
<style scoped>
.noResults {
  text-align: center;
  border-bottom: 1px solid lightgray;
}

tbody td, tbody th, table thead th{
  border: 1px solid #aaa;
}

thead th {
  text-transform: uppercase;
  font-size: 12px;
}

th.empty_cell, td.empty_cell {
  min-width: 60px;
  min-height: 20px;
}
</style>
