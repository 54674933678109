<template>
  <app-frame>
    <div slot='sidebar'>
      <li>
        <router-link class="nav-link"
          :class="$route.name === 'RequestList' && 'active'"
          :to="{ name: 'RequestList', params: { hospital: currentHospital.code }}">
          <i class="fa fa-fw fa-list"></i>Current Requests</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link"
          :class="$route.name === 'RequestNewStart' && 'active'"
          :to="{ name: 'RequestNewStart', params: { hospital: currentHospital.code }, query: { nextView: 'RequestPatientDetail' }}">
          <i class="fa fa-fw fa-plus"></i>New Request</router-link>
      </li>
      <li class="nav-item">
        <router-link class="nav-link"
          :class="$route.name === 'RequestStatisticsIndex' && 'active'"
          :to="{ name: 'RequestStatisticsIndex', params: { hospital: currentHospital.code }}">
          <i class="fa fa-fw fa-bar-chart"></i>Statistics</router-link>
      </li>
    </div>
    <div slot="main">
      <HospitalSwitcher></HospitalSwitcher>
      <router-view></router-view>
    </div>
  </app-frame>
</template>
<script>
import AppFrame from '@/components/AppFrame'
import HospitalSwitcher from '@/components/shared/HospitalSwitcher'
import { mapGetters } from 'vuex'
export default {
  name: 'Request',
  components: {
    'app-frame': AppFrame,
    HospitalSwitcher
  },
  computed: {
    ...mapGetters(['currentHospital'])
  }
}
</script>
